<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="600" @click:outside="closeOnOverlay">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <v-form v-model="isValid" @submit.prevent="handleSubmit(onSubmit)">
                    <v-card>
                        <v-card-title class="text-subtitle-1 text-sm-h5 mb-3"> {{ getTrad('RepetitionDialog.customFrequency') }} </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="3" md="4">
                                    <v-text-field flat solo dense hide-details="auto" type="text" readonly :value="getTrad('RepetitionDialog.repeatEvery')" />
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <ValidationProvider rules="required|min_value:1|max:4" :name="getTrad('RepetitionDialog.frequency')" v-slot="{ errors }">
                                        <v-text-field dense outlined v-model="repetitionFrequency" :error-messages="errors" hide-details="auto" type="number" min="1" />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-select
                                        v-model="repetitionTime"
                                        item-text="label"
                                        item-value="value"
                                        :items="repetitionTimeArray"
                                        hide-details="auto"
                                        :menu-props="{ offsetY: true }"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                            </v-row>

                            <v-container fluid class="px-0">
                                <p class="mb-0 mt-2 ml-2 black--text" v-if="repetitionTime == 'week'">{{ getTrad('RepetitionDialog.repeat') }}</p>
                                <v-row class="mt-0" v-if="repetitionTime == 'week'">
                                    <v-col cols="12" sm="8" md="7" class="d-flex justify-space-around">
                                        <button
                                            class="day-circle"
                                            :class="daysArray[index].isSelected ? 'day-circle-active' : ''"
                                            v-for="(day, index) in daysArray"
                                            :key="index"
                                            type="button"
                                            @click="toggleWeekDay(day.value, index)">
                                            {{ day.label }}
                                        </button>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="repetitionTime == 'month'">
                                    <v-col cols="12" sm="10" md="10">
                                        <v-select
                                            v-model="repeatMonthlyValue"
                                            item-text="label"
                                            item-value="value"
                                            :items="repeatMonthlyArray"
                                            hide-details="auto"
                                            :menu-props="{ offsetY: true }"
                                            dense
                                            outlined>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-container fluid>
                                <p class="mb-0 mt-2 black--text">{{ getTrad('RepetitionDialog.endsIn') }}</p>
                                <v-radio-group v-model="repetitionFinish">
                                    <v-radio :label="getTrad('RepetitionDialog.never')" value="never"></v-radio>
                                    <v-row no-gutters class="mt-2">
                                        <v-col cols="6" sm="4" md="4" class="d-flex align-center">
                                            <v-radio :label="getTrad('RepetitionDialog.in')" value="at"> </v-radio>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu v-model="isDatePickerActive" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="computedDateFormattedMomentjs"
                                                        readonly
                                                        dense
                                                        outlined
                                                        hide-details="auto"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :disabled="repetitionFinish == 'at' ? false : true"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="pickerDate" @input="isDatePickerActive = false" locale="PT-PT"> </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-6" no-gutters>
                                        <v-col cols="6" sm="4" md="4">
                                            <v-radio :label="getTrad('RepetitionDialog.after')" value="after"></v-radio>
                                        </v-col>
                                        <v-col cols="6" md="4">
                                            <ValidationProvider :rules="repetitionFinish == 'after' ? 'required|min_value:1' : ''" :name="getTrad('RepetitionDialog.occurrences')" v-slot="{ errors }">
                                                <v-text-field
                                                    min="1"
                                                    dense
                                                    outlined
                                                    :label="getTrad('RepetitionDialog.occurrences')"
                                                    v-model="endAfterOccurrences"
                                                    hide-details="auto"
                                                    type="number"
                                                    :error-messages="errors"
                                                    :disabled="repetitionFinish == 'after' ? false : true" />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-radio-group>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="error darken-1" text @click="closeOnOverlay"> {{ getTrad('RepetitionDialog.cancel') }} </v-btn>

                            <v-btn color="primary darken-1" type="submit" text> {{ getTrad('RepetitionDialog.save') }} </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
        </v-dialog>
    </v-row>
</template>

<script>
    import moment from 'moment';
    import 'moment/locale/pt';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import getTrad from '../i18n/getTrad';
    export default {
        props: ['isActive', 'eventStart'],
        mixins: [getTrad],
        components: {
            ValidationObserver,
            ValidationProvider,
        },

        data() {
            return {
                isActiveDialog: null,

                repetitionTimeArray: [
                    { value: 'day', label: '' },
                    { value: 'week', label: '' },
                    { value: 'month', label: '' },
                    { value: 'year', label: '' },
                ],

                daysArray: [
                    { value: 'sunday', label: 'D', messageLabel: '', isSelected: false, byDayRule: 'SU' },
                    { value: 'monday', label: 'S', messageLabel: '', isSelected: false, byDayRule: 'MO' },
                    { value: 'tuesday', label: 'T', messageLabel: '', isSelected: false, byDayRule: 'TU' },
                    { value: 'wednesday', label: 'Q', messageLabel: '', isSelected: false, byDayRule: 'WE' },
                    { value: 'thursday', label: 'Q', messageLabel: '', isSelected: false, byDayRule: 'TH' },
                    { value: 'friday', label: 'S', messageLabel: '', isSelected: false, byDayRule: 'FR' },
                    { value: 'saturday', label: 'S', messageLabel: '', isSelected: false, byDayRule: 'SA' },
                ],

                repetitionFrequency: 0,
                repetitionTime: 'day',
                repetitionFinish: 'never',

                endAfterOccurrences: '1',

                isValid: false,
                isDatePickerActive: false,
                pickerDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
                eventDay: null,

                repeatMonthlyArray: [
                    { value: null, label: null },
                    { value: null, label: null },
                ],
                repeatMonthlyValue: null,
                currentEventDay: null,
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            onSubmit() {
               
                let data = { value: '', label: '' };

                //check the type of label that will show to the user
                if (this.repetitionTime == 'day') {
                    if (this.repetitionFinish == 'never') {
                        if (this.repetitionFrequency == 1) {
                            data.value = 'RRULE:FREQ=DAILY';
                        } else {
                            data.value = `RRULE:FREQ=DAILY;INTERVAL=${this.repetitionFrequency}`;
                        }

                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.everyDay')
                                : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.days');
                    } else if (this.repetitionFinish == 'at') {
                        let finish = moment
                            .parseZone(this.pickerDate)
                            .utc()
                            .format()
                            .replace(/[\-\.\:]/g, '');

                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=DAILY;UNTIL=${finish}`;
                        } else {
                            data.value = `RRULE:FREQ=DAILY;INTERVAL=${this.repetitionFrequency};UNTIL=${finish}`;
                        }

                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.everyDayUntil') + this.computedDateFormattedMomentjs
                                : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.daysUntil') + this.computedDateFormattedMomentjs;
                    } else if (this.repetitionFinish == 'after') {
                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=DAILY;COUNT=${this.endAfterOccurrences}`;
                        } else {
                            data.value = `RRULE:FREQ=DAILY;INTERVAL=${this.repetitionFrequency};COUNT=${this.endAfterOccurrences}`;
                        }
                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.everyDay') + this.getTradC('RepetitionDialog.times', this.endAfterOccurrences)
                                : this.getTrad('RepetitionDialog.every') +
                                  this.repetitionFrequency +
                                  this.getTrad('RepetitionDialog.days') +
                                  this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                    }
                } else if (this.repetitionTime == 'week') {
                    if (this.repetitionFinish == 'never') {
                        let daysString = '';
                        let countSelectedWeekDays = 0;
                        let ruleByDay = '';
                        for (let index = 0; index < this.daysArray.length; index++) {
                            if (this.daysArray[index].isSelected == true) {
                                countSelectedWeekDays++;
                                daysString = daysString + ',' + this.daysArray[index].messageLabel;
                                ruleByDay = ruleByDay + this.daysArray[index].byDayRule + ',';
                            }
                        }

                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=WEEKLY;BYDAY=${ruleByDay}`;
                        } else {
                            data.value = `RRULE:FREQ=WEEKLY;BYDAY=${ruleByDay};INTERVAL=${this.repetitionFrequency};BYDAY=${ruleByDay}`;
                        }

                        if (this.repetitionFrequency == 1) {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label = this.getTrad('RepetitionDialog.weeklyEveryDay');
                            } else data.label = this.getTrad('RepetitionDialog.weekly') + daysString;
                        } else {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label = this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.weeksEveryDay');
                            } else data.label = this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.weeks') + daysString;
                        }
                    } else if (this.repetitionFinish == 'at') {
                        let ruleByDay = '';
                        let daysString = '';
                        let countSelectedWeekDays = 0;
                        for (let index = 0; index < this.daysArray.length; index++) {
                            if (this.daysArray[index].isSelected == true) {
                                countSelectedWeekDays++;
                                daysString = daysString + ',' + this.daysArray[index].messageLabel;
                                ruleByDay = ruleByDay + this.daysArray[index].byDayRule + ',';
                            }
                        }
                        let finish = moment
                            .parseZone(this.pickerDate)
                            .utc()
                            .format()
                            .replace(/[\-\.\:]/g, '');

                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=WEEKLY;BYDAY=${ruleByDay};UNTIL=${finish}`;
                        } else {
                            data.value = `RRULE:FREQ=WEEKLY;BYDAY=${ruleByDay};INTERVAL=${this.repetitionFrequency};UNTIL=${finish}`;
                        }

                        if (this.repetitionFrequency == 1) {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label = this.getTrad('RepetitionDialog.weeklyEveryDayUntil') + this.computedDateFormattedMomentjs;
                            } else data.label = this.getTrad('RepetitionDialog.weekly') + daysString + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs;
                        } else {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label =
                                    this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.weeklyEveryDayUntilLower') + this.computedDateFormattedMomentjs;
                            } else
                                data.label =
                                    this.getTrad('RepetitionDialog.every') +
                                    this.repetitionFrequency +
                                    this.getTrad('RepetitionDialog.weeks') +
                                    daysString +
                                    this.getTrad('RepetitionDialog.until') +
                                    this.computedDateFormattedMomentjs;
                        }
                    } else if (this.repetitionFinish == 'after') {
                        let daysString = '';
                        let countSelectedWeekDays = 0;
                        let ruleByDay = '';
                        for (let index = 0; index < this.daysArray.length; index++) {
                            if (this.daysArray[index].isSelected == true) {
                                countSelectedWeekDays++;
                                daysString = daysString + ',' + this.daysArray[index].messageLabel;
                                ruleByDay = ruleByDay + this.daysArray[index].byDayRule + ',';
                            }
                        }

                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=WEEKLY;COUNT=${this.endAfterOccurrences};BYDAY=${ruleByDay};COUNT=${this.endAfterOccurrences}`;
                        } else {
                            data.value = `RRULE:FREQ=WEEKLY;BYDAY=${ruleByDay};INTERVAL=${this.repetitionFrequency};COUNT=${this.endAfterOccurrences}`;
                        }

                        if (this.repetitionFrequency == 1) {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label = this.getTrad('RepetitionDialog.weeklyEveryDay') + this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                            } else data.label = this.getTrad('RepetitionDialog.weekly') + daysString + ', ' + this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                        } else {
                            if (countSelectedWeekDays == this.daysArray.length) {
                                data.label =
                                    this.getTrad('RepetitionDialog.every') +
                                    this.repetitionFrequency +
                                    this.getTrad('RepetitionDialog.weeksEveryDayUntil') +
                                    this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                            } else
                                data.label =
                                    this.getTrad('RepetitionDialog.every') +
                                    this.repetitionFrequency +
                                    this.getTrad('RepetitionDialog.weeks') +
                                    daysString +
                                    this.getTrad('RepetitionDialog.until') +
                                    this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                        }
                    }
                } else if (this.repetitionTime == 'month') {
                    if (this.repetitionFinish == 'never') {
                        if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = 'RRULE:FREQ=MONTHLY';
                        } else if (this.repetitionFrequency > 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency}`;
                        } else if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[1].value) {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue}`;
                        } else {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency};BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue}`;
                        }

                        if (this.repeatMonthlyValue == moment(this.currentEventDay).format('D')) {
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.getTrad('RepetitionDialog.monthlyOnTheDay') + this.repeatMonthlyValue
                                    : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.monthsAt') + this.repeatMonthlyValue;
                        } else
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.repeatMonthlyArray[1].label
                                    : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.months') + this.repeatMonthlyArray[1].label.toLowerCase();
                    } else if (this.repetitionFinish == 'at') {
                        let finish = moment
                            .parseZone(this.pickerDate)
                            .utc()
                            .format()
                            .replace(/[\-\.\:]/g, '');

                        if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = `RRULE:FREQ=MONTHLY;UNTIL=${finish}`;
                        } else if (this.repetitionFrequency > 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency};UNTIL=${finish}`;
                        } else if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[1].value) {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue};UNTIL=${finish}`;
                        } else {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency};BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue};UNTIL=${finish}`;
                        }
                        if (this.repeatMonthlyValue == moment(this.currentEventDay).format('D')) {
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.getTrad('RepetitionDialog.monthlyOnTheDay') + this.repeatMonthlyValue + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs
                                    : this.getTrad('RepetitionDialog.every') +
                                      this.repetitionFrequency +
                                      this.getTrad('RepetitionDialog.monthsAt') +
                                      this.repeatMonthlyValue +
                                      this.getTrad('RepetitionDialog.until') +
                                      this.computedDateFormattedMomentjs;
                        } else
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.repeatMonthlyArray[1].label + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs
                                    : this.getTrad('RepetitionDialog.every') +
                                      this.repetitionFrequency +
                                      this.getTrad('RepetitionDialog.months') +
                                      this.repeatMonthlyArray[1].label.toLowerCase() +
                                      this.getTrad('RepetitionDialog.until') +
                                      this.computedDateFormattedMomentjs;
                    } else if (this.repetitionFinish == 'after') {
                        if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = `RRULE:FREQ=MONTHLY;COUNT=${this.endAfterOccurrences}`;
                        } else if (this.repetitionFrequency > 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[0].value) {
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency};COUNT=${this.endAfterOccurrences}`;
                        } else if (this.repetitionFrequency == 1 && this.repeatMonthlyValue == this.repeatMonthlyArray[1].value) {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue};COUNT=${this.endAfterOccurrences}`;
                        } else {
                            let dayStr = moment(this.currentEventDay).locale('en').format('dd').toUpperCase();
                            data.value = `RRULE:FREQ=MONTHLY;INTERVAL=${this.repetitionFrequency};BYDAY=${dayStr};BYSETPOS=${this.repeatMonthlyValue};COUNT=${this.endAfterOccurrences}`;
                        }

                        if (this.repeatMonthlyValue == moment(this.currentEventDay).format('D')) {
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.getTrad('RepetitionDialog.monthlyOnTheDay') + this.repeatMonthlyValue + ', ' + this.getTradC('RepetitionDialog.times', this.endAfterOccurrences)
                                    : this.getTrad('RepetitionDialog.every') +
                                      this.repetitionFrequency +
                                      this.getTrad('RepetitionDialog.monthsAt') +
                                      this.repeatMonthlyValue +
                                      ', ' +
                                      this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                        } else
                            data.label =
                                this.repetitionFrequency == 1
                                    ? this.repeatMonthlyArray[1].label + ', ' + this.endAfterOccurrences + (this.endAfterOccurrences == 1 ? ' vez' : ' vezes')
                                    : this.getTrad('RepetitionDialog.every') +
                                      this.repetitionFrequency +
                                      this.getTrad('RepetitionDialog.months') +
                                      this.repeatMonthlyArray[1].label.toLowerCase() +
                                      ', ' +
                                      this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                    }
                } else if (this.repetitionTime == 'year') {
                    if (this.repetitionFinish == 'never') {
                        if (this.repetitionFrequency == 1) {
                            data.value = 'RRULE:FREQ=YEARLY';
                        } else {
                            data.value = `RRULE:FREQ=YEARLY;INTERVAL=${this.repetitionFrequency}`;
                        }

                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.annuallyAt') + moment(this.currentEventDay).format('D MMMM')
                                : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.yearsAt') + moment(this.currentEventDay).format('D MMMM');
                    } else if (this.repetitionFinish == 'at') {
                        let finish = moment
                            .parseZone(this.pickerDate)
                            .utc()
                            .format()
                            .replace(/[\-\.\:]/g, '');

                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=YEARLY;UNTIL=${finish}`;
                        } else {
                            data.value = `RRULE:FREQ=YEARLY;INTERVAL=${this.repetitionFrequency};UNTIL=${finish}`;
                        }

                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.annuallyUntil') + this.computedDateFormattedMomentjs
                                : this.getTrad('RepetitionDialog.every') + this.repetitionFrequency + this.getTrad('RepetitionDialog.yearsAt') + this.computedDateFormattedMomentjs;
                    } else if (this.repetitionFinish == 'after') {
                        if (this.repetitionFrequency == 1) {
                            data.value = `RRULE:FREQ=YEARLY;COUNT=${this.endAfterOccurrences}`;
                        } else {
                            data.value = `RRULE:FREQ=YEARLY;INTERVAL=${this.repetitionFrequency};COUNT=${this.endAfterOccurrences}`;
                        }

                        data.label =
                            this.repetitionFrequency == 1
                                ? this.getTrad('RepetitionDialog.annually') + this.getTradC('RepetitionDialog.times', this.endAfterOccurrences)
                                : this.getTrad('RepetitionDialog.every') +
                                  this.repetitionFrequency +
                                  this.getTrad('RepetitionDialog.years') +
                                  this.getTradC('RepetitionDialog.times', this.endAfterOccurrences);
                    }
                }

                this.$emit('getCustomRepetition', data);
            },
            toggleWeekDay(value, index) {
                if (this.daysArray[index].isSelected === false) {
                    this.daysArray[index].isSelected = true;
                } else {
                    this.daysArray[index].isSelected = false;
                }

                //filter array index different from null
                let isSomeDaySelected = this.daysArray.filter((item) => item.isSelected !== false);

                if (isSomeDaySelected.length == 0) {
                    this.daysArray[moment(this.currentEventDay).day()].isSelected = true;
                }
            },
        },

        computed: {
            getWeekDaysSelected() {
                return this.repetitionWeekDays;
            },

            computedDateFormattedMomentjs() {
                moment.locale('pt-pt');
                return this.pickerDate ? moment(this.pickerDate).format('D MMM YYYY') : '';
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                this.isActiveDialog = newVal;
            },

            repetitionFrequency: function (newVal, oldVal) {
                if (newVal > 1) {
                    for (let index = 0; index < this.repetitionTimeArray.length; index++) {
                        const element = this.repetitionTimeArray[index];
                        if (element.value == 'day') {
                            element.label = this.getTradC('RepetitionDialog.day', newVal);
                        } else if (element.value == 'week') {
                            element.label = this.getTradC('RepetitionDialog.week', newVal);
                        } else if (element.value == 'month') {
                            element.label = this.getTradC('RepetitionDialog.month', newVal);
                        } else if (element.value == 'year') {
                            element.label = this.getTradC('RepetitionDialog.year', newVal);
                        }
                    }
                } else if (newVal == 1) {
                    for (let index = 0; index < this.repetitionTimeArray.length; index++) {
                        const element = this.repetitionTimeArray[index];
                        if (element.value == 'day') {
                            element.label = this.getTradC('RepetitionDialog.day', newVal);
                        } else if (element.value == 'week') {
                            element.label = this.getTradC('RepetitionDialog.week', newVal);
                        } else if (element.value == 'month') {
                            element.label = this.getTradC('RepetitionDialog.month', newVal);
                        } else if (element.value == 'year') {
                            element.label = this.getTradC('RepetitionDialog.year', newVal);
                        }
                    }
                }
            },

            eventStart: function (newVal, oldVal) {
                //update current event day
                this.currentEventDay = moment(newVal);

                //toggle current day in week repetition array
                if (oldVal != '') {
                    this.daysArray[moment(moment(oldVal)).day()].isSelected = false;
                }
                this.daysArray[moment(this.currentEventDay).day()].isSelected = true;

                // Monthly at same day of month
                this.repeatMonthlyArray[0].value = moment(newVal).format('D');
                this.repeatMonthlyArray[0].label = this.getTrad('RepetitionDialog.monthlyAt') + moment(newVal).format('D');
                this.repeatMonthlyValue = moment(newVal).format('D');

                // Monthly at same day of week
                let day = moment(newVal);
                let nthOfMoth = Math.ceil(day.date() / 7);
                this.repeatMonthlyArray[1].value = nthOfMoth;

                if (nthOfMoth == 1) {
                    this.repeatMonthlyArray[1].label = this.getTrad('RepetitionDialog.monthlyFirst') + moment(newVal).format('dddd');
                } else if (nthOfMoth == 2) {
                    this.repeatMonthlyArray[1].label = this.getTrad('RepetitionDialog.monthlySecond') + moment(newVal).format('dddd');
                } else if (nthOfMoth == 3) {
                    this.repeatMonthlyArray[1].label = this.getTrad('RepetitionDialog.monthlyThird') + moment(newVal).format('dddd');
                } else if (nthOfMoth == 4) {
                    this.repeatMonthlyArray[1].label = this.getTrad('RepetitionDialog.monthlyFourth') + moment(newVal).format('dddd');
                } else this.repeatMonthlyArray[1].label = this.getTrad('RepetitionDialog.monthlyLast') + moment(newVal).format('dddd');
            },
        },

        created() {
            this.repetitionFrequency = 1;
            this.daysArray[0].messageLabel = this.getTrad('RepetitionDialog.sunday');
            this.daysArray[1].messageLabel = this.getTrad('RepetitionDialog.monday');
            this.daysArray[2].messageLabel = this.getTrad('RepetitionDialog.tuesday');
            this.daysArray[3].messageLabel = this.getTrad('RepetitionDialog.wednesday');
            this.daysArray[4].messageLabel = this.getTrad('RepetitionDialog.thursday');
            this.daysArray[5].messageLabel = this.getTrad('RepetitionDialog.friday');
            this.daysArray[6].messageLabel = this.getTrad('RepetitionDialog.saturday');
        },
    };
</script>

<style lang="scss" scoped>
    .day-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #eeeeee;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .day-circle-active {
        background-color: #2196f3;
        color: white;
    }

    .v-text-field {
        padding-top: 0px;
        margin-top: -6px;
    }
</style>
