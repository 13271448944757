<template>
    <div class="ma-5">
        <v-btn block color="primary" @click="$emit('open-evnt-handler-drawer')"> {{ getTrad('CalendarLeftSidebarContent.createEvent') }} </v-btn>

        <!-- Calendars -->
        <p class="text-xs text--disabled mt-8 mb-1 text-uppercase">{{ getTrad('CalendarLeftSidebarContent.calendars') }}</p>

        <!-- Calendar Options -->
        <v-checkbox v-model="checkAll" label="Ver Todos" hide-details></v-checkbox>
        <div v-for="item in calendarOptions" :key="item.label" style="cursor: pointer" class="d-flex align-center remove-icon">
            <v-row>
                <v-col cols="10" class="d-flex align-center">
                    <v-checkbox style="word-break: break-all" v-model="selectedCalendars" :value="item.label" :color="item.color" :label="item.label" class="mt-2" hide-details> </v-checkbox
                ></v-col>
                <v-col cols="2" class="d-flex align-center">
                    <v-tooltip bottom v-if="!item.primary">
                        <template v-slot:activator="{ attrs }">
                            <v-icon color="primary" dark v-bind="attrs" @click="openRemoveCalendarDialogHandler(item.id)">{{ closeIcon }}</v-icon>
                        </template>
                        <span>Anular subscrição de {{ item.label }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <v-btn color="primary" class="mt-4" elevation="2" plain @click="$emit('open-calendar-handler-drawer')">
            {{ getTrad('CalendarLeftSidebarContent.createCalendar') }}
            <v-icon dark right> {{ plusIcon }} </v-icon>
        </v-btn>

        <RemoveCalendarDialog :isActive="isDialogOpen" :isLoading="isDialogLoading" :showSnackbar="showSnackbar" @closeDialog="closeRemoveCalendarDialogHandler" @remove="removeCalendarHandler" />
    </div>
</template>

<script>
    import store from '@/store';
    import { mdiPlus, mdiClose } from '@mdi/js';

    import RemoveCalendarDialog from '../../components/RemoveCalendarDialog';
    import { deleteCalendar } from '@/api/google-calendar';

    import getTrad from '../../i18n/getTrad';

    export default {
        mixins: [getTrad],
        components: {
            RemoveCalendarDialog,
        },
        data() {
            return {
                plusIcon: mdiPlus,
                closeIcon: mdiClose,
                isDialogOpen: false,
                calendarId: null,
                isDialogLoading: false,
                showSnackbar: false,
            };
        },

        computed: {
            calendarOptions() {
                return store.getters['google-calendar/calendar/getCalendarOptions'];
            },

            selectedCalendars: {
                // getter
                get: function () {
                    return store.getters['google-calendar/calendar/getSelectedCalendars'];
                },
                // setter
                set: function (val) {
                    store.commit('google-calendar/calendar/setSelectedCalendars', val);
                    this.$emit('sync-events', false, null);
                },
            },
            checkAll: {
                // getter
                get: function () {
                    return this.selectedCalendars.length === this.calendarOptions.length;
                },
                // setter
                set: function (val) {
                    if (val) {
                        this.selectedCalendars = this.calendarOptions.map((i) => i.label);
                    } else if (this.selectedCalendars.length === this.calendarOptions.length) {
                        this.selectedCalendars = [];
                    }
                },
            },
        },

        methods: {
            openRemoveCalendarDialogHandler(id) {
                this.isDialogOpen = true;
                this.calendarId = id;
            },

            closeRemoveCalendarDialogHandler() {
                this.isDialogOpen = false;
            },

            removeCalendarHandler() {
                this.isDialogLoading = true;
                deleteCalendar(this.calendarId)
                    .then((response) => {
                        console.log(response);
                        console.log(this.selectedCalendars);
                        let arrayIndex = this.selectedCalendars.findIndex((calendarId) => calendarId == this.calendarId);
                        this.selectedCalendars.splice(arrayIndex, 1);
                        this.$emit('sync-calendars');
                        this.$emit('sync-events', false, null);
                        this.isDialogOpen = false;
                        this.isDialogLoading = false;
                    })

                    .catch((error) => {
                        console.log(error);
                        this.showSnackbar = true;
                        this.isDialogLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .remove-icon span,
    .remove-icon .v-icon {
        visibility: hidden;
    }
    .remove-icon:hover span,
    .remove-icon:hover .v-icon {
        visibility: visible;
    }
</style>
>
