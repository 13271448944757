<template>
    <div class="h-full">
        <div class="drawer-header d-flex align-center mb-4">
            <span class="font-weight-semibold text-base text--primary">{{ getTrad('CreateCalendar.title') }}</span>
            <v-spacer></v-spacer>

            <v-btn icon small @click="$emit('close-drawer')" v-if="!isLoading">
                <v-icon size="22">
                    {{ icons.mdiClose }}
                </v-icon>
            </v-btn>
        </div>
        <perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-calendar-event-handler">
            <v-form ref="refCreateCalendarHandlerForm" class="px-5 pt-3 pb-10" @submit.prevent="handleFormSubmit" lazy-validation>
                <v-text-field
                    :label="getTrad('CreateCalendar.name')"
                    :placeholder="getTrad('CreateCalendar.name')"
                    outlined
                    :rules="[rules.required]"
                    hide-details="auto"
                    dense
                    class="mb-6"
                    v-model="calendarName"></v-text-field>
                <v-textarea v-model="description" auto-grow outlined :label="getTrad('CreateCalendar.description')" :placeholder="getTrad('CreateCalendar.description')"></v-textarea>
                <v-select
                    v-model="selectedTimezone"
                    :label="getTrad('CreateCalendar.timezone')"
                    :placeholder="getTrad('CreateCalendar.timezone')"
                    item-text="label"
                    item-value="value"
                    :items="timezoneArray"
                    hide-details="auto"
                    :menu-props="{ offsetY: true }"
                    class="mb-6"
                    dense
                    outlined>
                </v-select>

                <v-btn :loading="isLoading" outlined class="me-3" @click="$emit('close-drawer')"> {{ getTrad('CreateCalendar.cancel') }} </v-btn>
                <v-btn :loading="isLoading" color="primary" type="submit"> {{ getTrad('CreateCalendar.create') }} </v-btn>
            </v-form>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import { mdiClose, mdiAlertCircle, mdiCheck } from '@mdi/js';
    import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
    import momentTimeZone from 'moment-timezone';
    import { newCalendar } from '@/api/google-calendar';
    import getTrad from '../i18n/getTrad';

    export default {
        components: {
            PerfectScrollbar,
        },
        mixins: [getTrad],
        data() {
            return {
                icons: {
                    mdiClose: mdiClose,
                },
                perfectScrollbarSettings: {
                    maxScrollbarLength: 60,
                    wheelPropagation: false,
                    wheelSpeed: 0.7,
                },

                rules: {
                    required: (value) => !!value || 'Obrigatório.',
                },

                timezoneArray: [],
                selectedTimezone: null,
                calendarName: null,
                calendarDescription: null,
                isLoading: false,

                description: '',

                snackbar: {
                    visible: false,
                    text: '',
                    title: '',
                    icon: '',
                    color: '',
                },
            };
        },

        methods: {
            getTimezoneArray() {
                momentTimeZone.tz
                    .names()
                    .reduce((memo, tz) => {
                        memo.push({
                            name: tz,
                            offset: momentTimeZone.tz(tz).utcOffset(),
                        });

                        return memo;
                    }, [])
                    .sort((a, b) => {
                        return a.offset - b.offset;
                    })
                    .reduce((memo, tz) => {
                        const timezone = tz.offset ? momentTimeZone.tz(tz.name).format('Z') : '';

                        this.timezoneArray.push({ value: tz.name, label: `(GMT${timezone}) ${tz.name}` });
                    }, '');
            },

            handleFormSubmit() {
                let isValid = this.$refs.refCreateCalendarHandlerForm.validate();
                if (isValid) {
                    this.isLoading = true;
                    let data = {
                        data: {
                            summary: this.calendarName,
                            description: this.description,
                            timeZone: this.selectedTimezone,
                        },
                    };

                    console.log(this.selectedTimezone);
                    console.log(data);

                    newCalendar(data)
                        .then((response) => {
                            console.log(response);

                            this.$snackbar({
                                text: this.getTrad('CreateCalendar.successText'),
                                color: 'success',
                                timeout: 3000,
                            });

                            this.calendarName = null;
                            this.description = null;
                            this.$refs.refCreateCalendarHandlerForm.reset();
                            this.$emit('sync-calendars');
                            setTimeout(() => this.$emit('close-drawer'), 1700);
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$snackbar({
                                text: this.getTrad('CreateCalendar.errorText'),
                                color: 'error',
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            },
        },

        created() {
            this.getTimezoneArray();
            this.selectedTimezone = momentTimeZone.tz.guess();
        },
    };
</script>
