<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="900" @click:outside="closeOnOverlay">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <v-form @submit.prevent="handleSubmit(onSubmit)">
                    <v-card>
                        <v-card-title class="text-subtitle-1 text-sm-h5"> {{ getTrad('DialogAllDayNotification.customNotification') }} </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select
                                        v-model="notificationMethod"
                                        item-text="label"
                                        item-value="value"
                                        :items="notificationMethodArray"
                                        hide-details="auto"
                                        :menu-props="{ offsetY: true }"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <ValidationProvider
                                        :name="getTrad('DialogAllDayNotification.time')"
                                        :rules="notificationTime == 'days' ? 'min_value:1|max_value:28|required' : 'min_value:1|max_value:4|required'"
                                        v-slot="{ errors }">
                                        <v-text-field dense outlined v-model="notificationTimeBefore" hide-details="auto" type="number" :error-messages="errors" />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select
                                        v-model="notificationTime"
                                        item-text="label"
                                        item-value="value"
                                        :items="notificationTimeArray"
                                        hide-details="auto"
                                        :menu-props="{ offsetY: true }"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                                <v-col cols="5" md="2">
                                    <v-text-field flat solo dense hide-details="auto" type="text" disabled :value="getTrad('DialogAllDayNotification.beforeAt')" />
                                </v-col>
                                <v-col cols="7" md="2">
                                    <v-select v-model="notificationHours" item-text="label" item-value="label" :items="hours" hide-details="auto" :menu-props="{ offsetY: true }" dense outlined>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="error darken-1" text @click="closeOnOverlay"> {{ getTrad('DialogAllDayNotification.cancel') }} </v-btn>

                            <v-btn color="primary darken-1" type="submit" text> {{ getTrad('DialogAllDayNotification.save') }} </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
        </v-dialog>
    </v-row>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import getTrad from '../i18n/getTrad';
    export default {
        mixins: [getTrad],
        props: ['isActive'],
        components: {
            ValidationObserver,
            ValidationProvider,
        },

        data() {
            return {
                isActiveDialog: null,
                notificationMethodArray: [
                    { value: 'notification', label: '' },
                    { value: 'email', label: '' },
                ],
                notificationTimeArray: [
                    { value: 'days', label: '' },
                    { value: 'weeks', label: '' },
                ],

                hours: [],

                notificationMethod: 'notification',
                notificationTime: 'days',
                notificationTimeBefore: '1',
                notificationHours: '9:00',
                isValid: false,
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            onSubmit() {
                let data = { value: '', label: '', method: '', minutes: '' };
                let convertedHours = this.convertHoursToMinutes(this.notificationHours);
                console.log(convertedHours);

                if (this.notificationMethod == 'notification') {
                    data.method = 'popup';

                    if (this.notificationTime == 'days') {
                        data.value = (this.notificationTimeBefore * 24 * 60 - convertedHours).toString();
                        data.minutes = +this.notificationTimeBefore * 24 * 60 - convertedHours;
                        data.label = this.getTradC('DialogAllDayNotification.daysNumber', this.notificationTimeBefore) + this.getTrad('DialogAllDayNotification.beforeAt') + this.notificationHours;
                    } else if (this.notificationTime == 'weeks') {
                        let oneWeekTime = 7 * 24 * 60;
                        data.value = (oneWeekTime * this.notificationTimeBefore - convertedHours).toString();
                        data.minutes = oneWeekTime * +this.notificationTimeBefore - convertedHours;
                        data.label = this.getTradC('DialogAllDayNotification.weeksNumber', this.notificationTimeBefore) + this.getTrad('DialogAllDayNotification.beforeAt') + this.notificationHours;
                    }
                } else if (this.notificationMethod == 'email') {
                    data.method = 'email';

                    if (this.notificationTime == 'days') {
                        data.value = (this.notificationTimeBefore * 24 * 60 - convertedHours).toString();
                        data.minutes = +this.notificationTimeBefore * 24 * 60 - convertedHours;
                        data.label =
                            this.getTradC('DialogAllDayNotification.daysNumber', this.notificationTimeBefore) +
                            this.getTrad('DialogAllDayNotification.beforeAt') +
                            this.notificationHours +
                            this.getTrad('DialogAllDayNotification.byEmail');
                    } else if (this.notificationTime == 'weeks') {
                        let oneWeekTime = 7 * 24 * 60;
                        data.value = (oneWeekTime * this.notificationTimeBefore - convertedHours).toString();
                        data.minutes = oneWeekTime * +this.notificationTimeBefore - convertedHours;
                        data.label =
                            this.getTradC('DialogAllDayNotification.weeksNumber', this.notificationTimeBefore) +
                            this.getTrad('DialogAllDayNotification.beforeAt') +
                            this.notificationHours +
                            this.getTrad('DialogAllDayNotification.byEmail');
                    }
                }

                this.$emit('getCustomNotification', data);
            },

            createHoursArray() {
                var x = 15; //minutes interval

                var tt = 0; // start time

                //loop to increment the time and push results in array
                for (var i = 0; tt < 24 * 60; i++) {
                    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format

                    var mm = tt % 60; // getting minutes of the hour in 0-55 format

                    this.hours[i] = ('0' + (hh % 12)).slice(-2) == '00' && i + 1 == 13 ? '12' + ':' + ('0' + mm).slice(-2) : hh + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                    tt = tt + x;
                }
            },

            convertHoursToMinutes(hour) {
                let timeParts = hour.split(':');
                return Number(timeParts[0]) * 60 + Number(timeParts[1]);
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                this.isActiveDialog = newVal;
            },
        },

        mounted() {
            this.notificationMethodArray[0].label = this.getTrad('DialogAllDayNotification.notification');
            this.notificationMethodArray[1].label = this.getTrad('DialogAllDayNotification.email');
            this.notificationTimeArray[0].label = this.getTrad('DialogAllDayNotification.days');
            this.notificationTimeArray[1].label = this.getTrad('DialogAllDayNotification.weeks');
            this.createHoursArray();
        },
    };
</script>

<style lang="scss" scoped>
    .select {
        max-width: 60px;
    }
</style>
