<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="600" @click:outside="closeOnOverlay">
            <v-card>
                <v-card-title class="text-subtitle-1 text-sm-h5"> {{ getTrad('RemoveEventDialog.title') }} </v-card-title>

                <v-card-text>{{ getTrad('RemoveEventDialog.text') }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error darken-1" :loading="loading" text @click="closeOnOverlay"> {{ getTrad('RemoveEventDialog.cancel') }} </v-btn>

                    <v-btn color="primary darken-1" :loading="loading" type="submit" @click="removeCalendar" text> {{ getTrad('RemoveEventDialog.remove') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import getTrad from '../i18n/getTrad';
    import { mdiAlertCircle } from '@mdi/js';
    export default {
        props: ['isActive', 'isLoading'],
        mixins: [getTrad],

        data() {
            return {
                isActiveDialog: null,
                loading: null,

                snackbar: {
                    visible: false,
                    icon: mdiAlertCircle,
                    color: 'error',
                },
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            removeCalendar() {
                this.$emit('remove');
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                this.isActiveDialog = newVal;
            },
            isLoading: function (newVal, oldVal) {
                this.loading = newVal;
            },
        },
    };
</script>
