<template>
    <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm" :loading="isLoading">
        <v-navigation-drawer v-model="isLeftSidebarOpen" width="250" mobile-breakpoint="sm" absolute touchless :right="$vuetify.rtl" :temporary="$vuetify.breakpoint.xsOnly" style="z-index: 2">
            <calendar-left-sidebar-content
                @open-evnt-handler-drawer="createEventHandler"
                @open-calendar-handler-drawer="openCreateCalendarHandler"
                @sync-calendars="syncCalendars"
                @sync-events="syncEvents"></calendar-left-sidebar-content>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="isEventHandlerDrawerActive" :right="!$vuetify.rtl" touchless app temporary width="420">
            <calendar-event-handler-drawer-content
                :event="event"
                :clear-event-data="clearEventData"
                :filteredCalendars="filteredCalendars"
                @close-drawer="isEventHandlerDrawerActive = false"
                @sync-events="syncEvents"></calendar-event-handler-drawer-content>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="isCreateCalendarActive" :right="!$vuetify.rtl" touchless app temporary width="420">
            <create-calendar @close-drawer="isCreateCalendarActive = false" @sync-calendars="syncCalendars"></create-calendar>
        </v-navigation-drawer>

        <div :style="contentStyles" class="h-full">
            <div v-if="refCalendar" class="calendar-header mx-6 my-5 d-flex align-center flex-wrap">
                <v-btn icon class="d-inline-block d-md-none me-1" @click="isLeftSidebarOpen = true">
                    <v-icon size="30">
                        {{ icons.mdiMenu }}
                    </v-icon>
                </v-btn>
                <v-btn icon class="me-1" @click="calendarPrevHandler()">
                    <v-icon size="30">
                        {{ icons.mdiChevronLeft }}
                    </v-icon>
                </v-btn>
                <v-btn icon class="me-3" @click="calendarNextHandler()">
                    <v-icon size="30">
                        {{ icons.mdiChevronRight }}
                    </v-icon>
                </v-btn>
                <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
                    {{ refCalendar.title }}
                </p>

                <v-spacer></v-spacer>

                <v-btn-toggle v-model="activeCalendarView" color="primary" class="mt-3 mt-sm-0">
                    <v-btn v-for="calendarViewOption in calendarViewOptions" :key="calendarViewOption" elevation="0" :value="calendarViewOption">
                        {{ calendarViewOption == 'month' ? getTrad('Calendar.month') : calendarViewOption == 'week' ? getTrad('Calendar.week') : getTrad('Calendar.day') }}
                    </v-btn>
                </v-btn-toggle>
            </div>

            <v-calendar
                ref="refCalendar"
                v-model="value"
                :type="activeCalendarView"
                :events="fetchedEvents"
                :event-margin-bottom="5"
                :event-text-color="getEventTextColor"
                @click:event="calendarEventClickHandler"
                @click:day="calendarHandleDayClick"
                @click:time="calendarHandleDayClick"
                @click:more="calendarHandleMoreClick"
                locale="pt"
                :key="refreshKey">
            </v-calendar>
            <v-sheet>
                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x max-height="600px">
                    <v-card color="grey lighten-4" :min-width="$vuetify.breakpoint.smAndDown ? '200px' : '400px'" :max-width="$vuetify.breakpoint.smAndDown ? '280px' : '350px'" flat>
                        <v-toolbar elevation="0" :color="getEventColor(selectedEvent.calendarId)" height="auto">
                            <v-toolbar-title class="text-subtitle-1 text-md-h6 toolbar-title">{{ selectedEvent.name }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon color="white" v-if="verifyAccessRole(selectedEvent.calendarId)" @click="editEventHandler(selectedEvent)">
                                <v-icon> {{ icons.mdiPencil }} </v-icon>
                            </v-btn>
                            <v-btn icon color="white" v-if="verifyAccessRole(selectedEvent.calendarId)" @click="openRemoveEventDialogHandler(selectedEvent.calendarId, selectedEvent.id)">
                                <v-icon>{{ icons.mdiDelete }}</v-icon>
                            </v-btn>
                            <v-btn icon color="white" @click="selectedOpen = false">
                                <v-icon>{{ icons.mdiClose }}</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="mt-4" v-if="!selectedEvent.timed">
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text">{{ selectedEvent.start | momentDate }} </span>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text" v-if="selectedEvent.end">{{ selectedEvent.start | momentHour }} -</span>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text">{{ selectedEvent.end | momentDate }} </span>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text" v-if="selectedEvent.end">{{ selectedEvent.end | momentHour }}</span>
                        </v-card-text>
                        <v-card-text class="mt-4" v-else>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text">{{ selectedEvent.start | moment }} </span>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text" v-if="selectedEvent.end">{{ selectedEvent.start | momentHour }} -</span>
                            <span class="text-subtitle-2 text-md-subtitle-1 black--text" v-if="selectedEvent.end">{{ selectedEvent.end | momentHour }}</span>
                        </v-card-text>

                        <v-card-text class="mt-2 d-flex align-center" v-if="selectedEvent.hangoutLink">
                            <v-icon size="30" color="primary "> {{ icons.mdiVideo }}</v-icon>
                            <v-btn class="ml-2" elevation="0" color="primary" :href="selectedEvent.hangoutLink" target="_blank">{{ getTrad('Calendar.participate') }}</v-btn>
                        </v-card-text>

                        <v-expansion-panels v-if="selectedEvent.attendees" class="px-1">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div class="d-flex align-center">
                                        <v-icon size="30" color="primary "> {{ icons.mdiAccountMultiple }}</v-icon>
                                        <span class="black--text ml-2">{{ selectedEvent.attendees.length }} {{ getTrad('Calendar.attendees') }}</span>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list-item v-for="(user, index) in selectedEvent.attendees" :key="index" class="my-4">
                                        <!--   <v-list-item-avatar>
                                            <v-img v-if="verifyPhoto(user.email)" :src="verifyPhoto(user.email)" referrerpolicy="no-referrer"></v-img>
                                            <v-icon v-else size="30" color="primary "> {{ icons.mdiAccount }}</v-icon>
                                        </v-list-item-avatar> -->
                                        <v-list-item-content>
                                            <v-list-item-title>{{ verifyName(user.email) }}</v-list-item-title>
                                            <v-list-item-title>{{ user.email }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-card-text class="mt-2 d-flex align-center" v-if="selectedEvent.attachments">
                            <v-icon size="30" color="primary "> {{ icons.mdiPaperclip }}</v-icon>
                            <div>
                                <v-list-item v-for="(item, index) in selectedEvent.attachments" :key="item.fileId" class="mb-3">
                                    <v-list-item-content>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    text
                                                    elevation="2"
                                                    class="text-truncate justify-start text-caption px-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    max-width="140px"
                                                    :href="item.fileUrl"
                                                    target="_blank"
                                                    >{{ item.title }}</v-btn
                                                >
                                            </template>
                                            <span>{{ item.title }}</span>
                                        </v-tooltip>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </v-card-text>

                        <v-card-text class="mt-2 d-flex align-center">
                            <v-icon size="30" color="primary "> {{ icons.mdiCalendar }}</v-icon>
                            <span v-if="selectedEvent.organizer != undefined && selectedEvent.organizer.displayName" class="text-subtitle-2 text-md-subtitle-1 black--text ml-2">{{
                                selectedEvent.organizer.displayName
                            }}</span>
                            <span v-else-if="selectedEvent.organizer != undefined" class="text-subtitle-2 text-md-subtitle-1 black--text ml-2">{{ selectedEvent.organizer.email }}</span>
                        </v-card-text>
                    </v-card>
                </v-menu>
            </v-sheet>
        </div>
        <RemoveEventDialog :isActive="isDialogOpen" :isLoading="isDialogLoading" @closeDialog="closeRemoveEventDialogHandler" @remove="removeEventHandler" />
    </v-card>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import { ref, onUnmounted, computed, watch } from '@vue/composition-api';
    import { mdiChevronLeft, mdiChevronRight, mdiMenu, mdiPencil, mdiDelete, mdiVideo, mdiAccountMultiple, mdiPaperclip, mdiClose, mdiCalendar, mdiAccount } from '@mdi/js';
    import { useResponsiveLeftSidebar } from '@core/comp-functions/ui';
    import store from '@/store';
    import { hexToRgb, getVuetify } from '@core/utils';
    import calendarStoreModule from '../../store/calendar';
    import moment from 'moment';
    import getTrad from '../../i18n/getTrad';

    // Local Components
    import CalendarLeftSidebarContent from './CalendarLeftSidebarContent.vue';
    import CalendarEventHandlerDrawerContent from './CalendarEventHandlerDrawerContent.vue';
    import CreateCalendar from '../../components/CreateCalendar.vue';
    import RemoveEventDialog from '../../components/RemoveEventDialog.vue';

    //api
    import { listCalendars, getContacts } from '@/api/google-calendar';
    import { listEvents, deleteEvent, getEventById } from '@/api/events';

    export default {
        components: {
            CalendarLeftSidebarContent,
            CalendarEventHandlerDrawerContent,
            CreateCalendar,
            RemoveEventDialog,
        },
        mixins: [getTrad],

        data() {
            return {
                isCreateCalendarActive: false,
                receivedCalendars: null,

                value: '',
                isLoading: false,
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                hasCalendars: false,
                icons: {
                    mdiChevronLeft,
                    mdiChevronRight,
                    mdiMenu,
                    mdiPencil,
                    mdiDelete,
                    mdiVideo,
                    mdiAccountMultiple,
                    mdiPaperclip,
                    mdiClose,
                    mdiCalendar,
                    mdiAccount,
                },
                filteredCalendars: [],
                isDialogOpen: false,
                isDialogLoading: false,
                eventToRemoveId: null,
                calendarId: null,
                refreshKey: 0,
            };
        },

        computed: {
            fetchedEvents: {
                // getter
                get: function () {
                    return store.getters['google-calendar/calendar/getEvents'];
                },
                // setter
                set: function (val) {
                    console.log(val);
                    store.commit('google-calendar/calendar/setEvents', val);

                    /* this.$emit('sync-events'); */
                },
            },
        },

        methods: {
            createEventHandler() {
                console.log(this.event);
                if (this.event.start == '') {
                    this.event.start = new Date();
                }

                this.isEventHandlerDrawerActive = true;
            },

            openCreateCalendarHandler() {
                this.isCreateCalendarActive = true;
            },

            async syncCalendars() {
                this.isLoading = true;
                await listCalendars()
                    .then((response) => {
                        console.log(response);
                        if (response.data.data.url) {
                            window.location.replace(response.data.data.url);
                        } else {
                            this.receivedCalendars = response.data.data.items;

                            let filteredArrays = [];
                            let filteredLabels = [];

                            this.receivedCalendars.map((calendar) => {
                                filteredArrays.push({
                                    id: calendar.id,
                                    label: calendar.summary,
                                    color: calendar.backgroundColor,
                                    accessRole: calendar.accessRole,
                                    primary: calendar.primary ? calendar.primary : false,
                                    timeZone: calendar.timeZone,
                                });
                                filteredLabels.push(calendar.summary);
                                if (calendar.accessRole == 'owner' || calendar.accessRole == 'writer') {
                                    this.filteredCalendars.push({
                                        id: calendar.id,
                                        label: calendar.summary,
                                        color: calendar.backgroundColor,
                                    });
                                }
                            });
                            store.commit('google-calendar/calendar/setCalendarOptions', filteredArrays);

                            if (!this.hasCalendars) {
                                store.commit('google-calendar/calendar/setSelectedCalendars', filteredLabels);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        // this.$snackbar({
                        //     text: this.getTrad('GoogleSendPermissions.errorText'),
                        //     color: 'error',
                        //     timeout: 3000,
                        // });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async getCalendarsEvents(date, isEditting, eventId) {
                this.isLoading = true;
                let selectedCalendarsArray = [];

                selectedCalendarsArray = store.getters['google-calendar/calendar/getCalendarOptions'].map((calendar) => {
                    if (store.getters['google-calendar/calendar/getSelectedCalendars'].includes(calendar.label)) {
                        return calendar.id;
                    }
                });

                let previousMonth = moment(date).subtract(2, 'months').startOf('month').toISOString();

                let nextMonth = moment(date).add(2, 'months').startOf('month').toISOString();

                let query = {
                    singleEvents: true,
                    orderBy: 'starttime',
                    timeMin: previousMonth,
                    timeMax: nextMonth,
                    calendars: selectedCalendarsArray.join(),
                };

                let filteredSelectedArray = selectedCalendarsArray.filter((calendar) => {
                    if (calendar != undefined) {
                        return calendar;
                    }
                });

                await listEvents(query)
                    .then((response) => {
                        console.log(response);
                        let fetchedCloneArray = this.fetchedEvents.filter((event) => {
                            if (filteredSelectedArray.includes(event.calendarId)) {
                                return event;
                            }
                        });

                        this.fetchedEvents = fetchedCloneArray;
                        if (!isEditting) {
                            Object.keys(response.data).forEach((calendar) => {
                                response.data[calendar].forEach((event) => {
                                    let isEventDuplicated = this.fetchedEvents.filter((fetchedEvent) => fetchedEvent.id == event.id);
                                    if (isEventDuplicated.length == 0) {
                                        this.fetchedEvents.push({
                                            name: event.summary ? event.summary : 'Sem Título',
                                            start: this.verifyStart(event.start),
                                            end: this.verifyEnd(event.end),
                                            color: this.getEventColor(calendar),
                                            timed: this.verifyTime(event.start),
                                            id: event.id,
                                            calendarId: calendar,
                                            attendees: event.attendees ? event.attendees : null,
                                            hangoutLink: event.hangoutLink ? event.hangoutLink : null,
                                            attachments: event.attachments ? event.attachments : null,
                                            organizer: event.organizer ? event.organizer : null,
                                            creator: event.creator ? event.creator : null,
                                            recurringEventId: event.recurringEventId ? event.recurringEventId : null,
                                            location: event.location ? event.location : null,
                                            description: event.description ? event.description : null,
                                            reminders: event.reminders,
                                        });
                                    }
                                });
                            });
                        } else {
                            Object.keys(response.data).forEach((calendar) => {
                                response.data[calendar].forEach((event) => {
                                    if (event.id == eventId) {
                                        let arrayIndex = this.fetchedEvents.findIndex((fetchedEvent) => fetchedEvent.id == eventId);

                                        this.fetchedEvents[arrayIndex] = {
                                            name: event.summary ? event.summary : 'Sem Título',
                                            start: this.verifyStart(event.start),
                                            end: this.verifyEnd(event.end),
                                            color: this.getEventColor(calendar),
                                            timed: this.verifyTime(event.start),
                                            id: event.id,
                                            calendarId: calendar,
                                            attendees: event.attendees ? event.attendees : null,
                                            hangoutLink: event.hangoutLink ? event.hangoutLink : null,
                                            attachments: event.attachments ? event.attachments : null,
                                            organizer: event.organizer ? event.organizer : null,
                                            creator: event.creator ? event.creator : null,
                                            recurringEventId: event.recurringEventId ? event.recurringEventId : null,
                                            location: event.location ? event.location : null,
                                            description: event.description ? event.description : null,
                                            reminders: event.reminders,
                                        };
                                    }
                                });
                            });
                            this.refreshKey++;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        // this.$snackbar({
                        //     text: this.getTrad('GoogleSendPermissions.errorText'),
                        //     color: 'error',
                        //     timeout: 3000,
                        // });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            getEventColor(calendar) {
                let color;
                store.getters['google-calendar/calendar/getCalendarOptions'].filter((calendarOption) => {
                    if (calendarOption.id == calendar) {
                        color = calendarOption.color;
                    }
                });
                return color;
            },

            verifyAccessRole(calendar) {
                let accessRole;
                store.getters['google-calendar/calendar/getCalendarOptions'].filter((calendarOption) => {
                    if (calendarOption.id == calendar) {
                        if (calendarOption.accessRole == 'reader') {
                            accessRole = false;
                        } else {
                            accessRole = true;
                        }
                    }
                });
                return accessRole;
            },

            calendarPrevHandler(event) {
                this.refCalendar.prev();

                this.getCalendarsEvents(this.value, false, null);
            },
            calendarNextHandler(event) {
                this.refCalendar.next();

                this.getCalendarsEvents(this.value, false, null);
            },

            verifyTime(time) {
                if ('dateTime' in time) {
                    return true;
                } else if ('date' in time) {
                    return false;
                }
            },
            verifyStart(time) {
                if ('dateTime' in time) {
                    return new Date(time.dateTime);
                } else if ('date' in time) {
                    return new Date(time.date);
                }
            },
            verifyEnd(time) {
                if ('dateTime' in time) {
                    return new Date(time.dateTime);
                } else if ('date' in time) {
                    return new Date(time.date);
                } else return null;
            },

            syncEvents(isEditting, eventId) {
                let date;

                if (this.value != '') {
                    date = this.value;
                } else {
                    date = new Date();
                }

                this.getCalendarsEvents(date, isEditting, eventId);
            },

            calendarEventClickHandler({ nativeEvent, event }) {
                console.log(event);
                const open = () => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
                };

                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => open()));
                } else {
                    open();
                }

                nativeEvent.stopPropagation();
            },

            syncContacts() {
                getContacts()
                    .then((response) => {
                        console.log(response);

                        response.data.data.map((contact) => {
                            if (!contact.name) {
                                contact.name = contact.email;
                            }
                        });

                        let sortedArray = response.data.data.sort((a, b) => a.name.localeCompare(b.name));

                        store.commit('google-calendar/calendar/setContacts', sortedArray);
                    })
                    .catch((error) => {
                        console.log(error);
                        // this.$snackbar({
                        //     text: this.getTrad('GoogleSendPermissions.errorText'),
                        //     color: 'error',
                        //     timeout: 3000,
                        // });
                    });
            },

            verifyName(email) {
                let name;
                store.getters['google-calendar/calendar/getContacts'].filter((contact) => {
                    if (contact.email == email) {
                        name = contact.name;
                    }
                });
                return name;
            },

            verifyPhoto(email) {
                let photo;
                store.getters['google-calendar/calendar/getContacts'].filter((contact) => {
                    if (contact.email == email) {
                        if (contact.photo) {
                            photo = contact.photo;
                        }
                    }
                });
                return photo;
            },

            removeEventHandler() {
                deleteEvent(this.calendarId, this.eventToRemoveId)
                    .then((response) => {
                        console.log(response);

                        let fetchedCloneArray = this.fetchedEvents.filter((event, index) => {
                            if (event.id != this.eventToRemoveId) {
                                return event;
                            }
                        });
                        this.fetchedEvents = fetchedCloneArray;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$snackbar({
                            text: this.getTrad('RemoveEventDialog.errorText'),
                            color: 'error',
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        this.selectedOpen = false;
                        this.isDialogOpen = false;
                    });
            },

            editEventHandler(event) {
                console.log(event);

                if (event.recurringEventId) {
                    getEventById(event.calendarId, event.recurringEventId)
                        .then((response) => {
                            console.log(response);
                            this.event.recurrence = response.data.data.recurrence[0];
                            this.event.hasRecurrence = true;
                            this.event.start = event.start;
                            this.event.id = event.id;
                            this.event.name = event.name;
                            this.event.end = event.end;
                            this.event.calendar = event.calendarId;
                            this.event.timed = !event.timed;
                            this.event.startHour = moment(event.start).format('H:mm');
                            this.event.endHour = moment(event.end).format('H:mm');
                            this.event.recurringEventId = event.recurringEventId;
                            this.event.isEditting = true;
                            this.event.location = event.location;
                            this.event.description = event.description;
                            this.event.guests = event.attendees;
                            this.event.reminder = event.reminders;

                            this.fetchedEvents.filter((fetchedEvent) => {
                                if (fetchedEvent.id == event.id) {
                                    console.log(fetchedEvent);
                                    this.event.hangoutLink = fetchedEvent.hangoutLink;
                                }
                            });
                            this.selectedOpen = false;
                            this.isEventHandlerDrawerActive = true;
                        })
                        .catch((error) => {
                            console.log(error);
                            // this.$snackbar({
                            //     text: this.getTrad('GoogleSendPermissions.errorText'),
                            //     color: 'error',
                            //     timeout: 3000,
                            // });
                        });
                } else {
                    this.event.recurrence = null;
                    this.event.hasRecurrence = true;
                    this.event.start = event.start;
                    this.event.id = event.id;
                    this.event.name = event.name;
                    this.event.end = event.end;
                    this.event.calendar = event.calendarId;
                    this.event.timed = !event.timed;
                    this.event.startHour = moment(event.start).format('H:mm');
                    this.event.endHour = moment(event.end).format('H:mm');
                    this.event.location = event.location;
                    this.event.description = event.description;
                    this.event.guests = event.attendees;
                    this.event.isEditting = true;
                    this.event.reminder = event.reminders;

                    this.fetchedEvents.filter((fetchedEvent) => {
                        if (fetchedEvent.id == event.id) {
                            console.log(fetchedEvent);
                            this.event.hangoutLink = fetchedEvent.hangoutLink;
                        }
                    });
                    this.selectedOpen = false;
                    this.isEventHandlerDrawerActive = true;
                }
            },

            closeRemoveEventDialogHandler() {
                this.isDialogOpen = false;
            },
            openRemoveEventDialogHandler(calendarId, eventId) {
                this.calendarId = calendarId;
                this.eventToRemoveId = eventId;
                this.isDialogOpen = true;
            },
        },

        filters: {
            moment: function (date) {
                return moment(date).format('dddd, D MMMM');
            },

            momentHour: function (date) {
                return moment(date).format('HH:mm');
            },
            momentDate: function (date) {
                return moment(date).format('D MMMM');
            },
        },

        async beforeMount() {
            // if (store.getters['google-calendar/calendar/getSelectedCalendars'].length > 0) {
            //     this.hasCalendars = true;
            // } else this.hasCalendars = false;

            // await this.syncCalendars();
            // await this.getCalendarsEvents(new Date(), false, null);
            // this.syncContacts();
        },

        setup() {
            // ————————————————————————————————————
            //* ——— Store Registration
            // ————————————————————————————————————

            const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar';

            // Register module
            if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
                store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule);
            }

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME);
            });

            // ————————————————————————————————————
            //* ——— Vuetify Instance
            // ————————————————————————————————————

            const $vuetify = getVuetify();

            // ————————————————————————————————————
            //* ——— Left Sidebar
            // ————————————————————————————————————
            const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 });

            // ————————————————————————————————————
            //* ——— Templare Ref
            // ————————————————————————————————————

            const refCalendar = ref(null);

            // ————————————————————————————————————
            //* ——— Calendar View/Type
            // ————————————————————————————————————

            const activeCalendarView = ref('month');
            const calendarViewOptions = ['month', 'week', 'day'];

            // ————————————————————————————————————
            //* ——— Calendar Value & Events
            // ————————————————————————————————————

            const calendarValue = ref();
            const calendarEvents = ref([]);

            const blankEvent = {
                id: '',
                name: '',
                start: '',
                end: '',
                timed: false,
                extendedProps: {
                    calendar: '',
                    guests: [],
                    location: '',
                    description: '',
                },
            };
            const event = ref(JSON.parse(JSON.stringify(blankEvent)));
            const clearEventData = () => {
                event.value = JSON.parse(JSON.stringify(blankEvent));
            };

            const getEventTextColor = (e) => e.eventTextColor;

            //
            //* ——— Calendar Event Handler ——————————————————
            //

            // TODO: Remove this once issue is resolved
            // Please check: https://github.com/vuetifyjs/vuetify/issues/13900
            const eventClickBugWorkaround = ref(false);

            const calenderHandleEventClick = (eventObj) => {
                console.log(eventObj);
                // Grab only event object
                event.value = eventObj.event;

                // eslint-disable-next-line no-use-before-define
                isEventHandlerDrawerActive.value = true;

                // Bug workaround
                eventClickBugWorkaround.value = true;
            };

            const calendarHandleDayClick = (dayObj) => {
                console.log(dayObj);
                // Bug workaround
                if (eventClickBugWorkaround.value) {
                    eventClickBugWorkaround.value = false;

                    return;
                }
                let down = true;

                const minutes = 15;
                const ms = 1000 * 60 * minutes;

                let date = new Date(dayObj.year, dayObj.month - 1, dayObj.day, dayObj.hour, dayObj.minute);

                /*  console.log(moment(new Date(Math.round(date.getTime() / ms) * ms), 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')); */

                event.value.start = dayObj.date;
                event.value.roundedHour = moment(new Date(Math.round(date.getTime() / ms) * ms), 'ddd DD-MMM-YYYY, hh:mm').format('H:mm');

                // eslint-disable-next-line no-use-before-define
                isEventHandlerDrawerActive.value = true;
            };

            const calendarHandleMoreClick = ({ date }) => {
                calendarValue.value = date;

                activeCalendarView.value = 'day';

                // Bug workaround
                eventClickBugWorkaround.value = true;
            };

            //
            //* ——— Event Actions ——————————————————
            //

            const addEvent = (eventData) => {
                // Only get date from event
                /* eslint-disable no-param-reassign */
                if (eventData.start) eventData.start = new Date(eventData.start).toISOString().substr(0, 10);
                if (eventData.end) eventData.end = new Date(eventData.end).toISOString().substr(0, 10);

                eventData.timed = false;
                /* eslint-enable */
            };

            /* const updateEvent = (eventData) => {
                   store.dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/updateEvent`, { event: eventData }).then((response) => {
                       const updatedEvent = response.data.event;

                       updatedEvent.color = store.state[CALENDAR_APP_STORE_MODULE_NAME].calendarOptions.find((calendar) => calendar.label === updatedEvent.extendedProps.calendar).color;

                       const updatedEventIndex = calendarEvents.value.findIndex((e) => e.id === updatedEvent.id);

                       calendarEvents.value.splice(updatedEventIndex, 1, updatedEvent);
                   });
               }; */

            const removeEvent = () => {
                const eventId = event.value.id;
                store.dispatch(`${CALENDAR_APP_STORE_MODULE_NAME}/removeEvent`, { id: eventId }).then(() => {
                    const updatedEventIndex = calendarEvents.value.findIndex((e) => e.id === eventId);
                    calendarEvents.value.splice(updatedEventIndex, 1);
                });
            };

            //
            //* ——— Selected Calendars ——————————————————
            //
            const selectedCalendars = computed(() => store.state[CALENDAR_APP_STORE_MODULE_NAME].selectedCalendars);

            // ————————————————————————————————————
            //* ——— Event Handler
            // ————————————————————————————————————

            const isEventHandlerDrawerActive = ref(false);
            watch(isEventHandlerDrawerActive, (val) => {
                if (!val) clearEventData();
            });

            return {
                // Left Sidebar
                isLeftSidebarOpen,
                contentStyles,

                // Template Refs
                refCalendar,

                getEventTextColor,

                // Calendar View/Type
                activeCalendarView,
                calendarViewOptions,

                // Calendar Value & Events
                calendarValue,
                calendarEvents,
                event,
                clearEventData,

                calendarHandleDayClick,
                calendarHandleMoreClick,
                calenderHandleEventClick,

                addEvent,
                /*    updateEvent, */
                removeEvent,

                // Event handler sidebar
                isEventHandlerDrawerActive,

                // Icons
            };
        },
    };
</script>

<style lang="scss">
    @import '~@core/preset/preset/apps/calendar.scss';

    .theme--light.v-calendar-weekly .v-calendar-weekly__day,
    .theme--light.v-sheet,
    .theme--light.v-calendar-daily .v-calendar-daily_head-day {
        color: white !important;
    }

    .v-expansion-panel::before,
    .v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
        box-shadow: none !important;
    }

    .toolbar-title {
        height: auto !important;
        min-height: 56px;
        white-space: unset !important;
        text-overflow: unset !important;
        overflow: unset !important;
        display: flex;
        align-items: center;
    }
</style>
