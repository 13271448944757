<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="600" @click:outside="closeOnOverlay">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <v-form @submit.prevent="handleSubmit(onSubmit)">
                    <v-card>
                        <v-card-title class="text-subtitle-1 text-sm-h5"> {{ getTrad('DialogNotAllDayNotification.customNotification') }} </v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="notificationMethod"
                                        item-text="label"
                                        item-value="value"
                                        :items="notificationMethodArray"
                                        hide-details="auto"
                                        :menu-props="{ offsetY: true }"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <ValidationProvider
                                        :name="getTrad('DialogNotAllDayNotification.time')"
                                        :rules="
                                            notificationTime == 'minutes'
                                                ? 'min_value:1|max_value:40320|required'
                                                : notificationTime == 'hours'
                                                ? 'min_value:1|max_value:672|required'
                                                : notificationTime == 'days'
                                                ? 'min_value:1|max_value:28|required'
                                                : 'min_value:1|max_value:4|required'
                                        "
                                        v-slot="{ errors }">
                                        <v-text-field dense outlined v-model="notificationTimeBefore" hide-details="auto" type="number" :error-messages="errors" />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="notificationTime"
                                        item-text="label"
                                        item-value="value"
                                        :items="notificationTimeArray"
                                        hide-details="auto"
                                        :menu-props="{ offsetY: true }"
                                        dense
                                        outlined>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="error darken-1" text @click="closeOnOverlay"> {{ getTrad('DialogNotAllDayNotification.cancel') }} </v-btn>

                            <v-btn color="primary darken-1" type="submit" text> {{ getTrad('DialogNotAllDayNotification.save') }} </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </ValidationObserver>
        </v-dialog>
    </v-row>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import getTrad from '../i18n/getTrad';
    export default {
        mixins: [getTrad],
        props: ['isActive'],
        components: {
            ValidationObserver,
            ValidationProvider,
        },

        data() {
            return {
                isActiveDialog: null,
                notificationMethodArray: [
                    { value: 'notification', label: '' },
                    { value: 'email', label: '' },
                ],
                notificationTimeArray: [
                    { value: 'minutes', label: '' },
                    { value: 'hours', label: '' },
                    { value: 'days', label: '' },
                    { value: 'weeks', label: '' },
                ],

                notificationMethod: 'notification',
                notificationTime: 'minutes',
                notificationTimeBefore: '10',
                isValid: false,
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            onSubmit() {
                let data = { value: '', label: '', method: '', minutes: '' };

                if (this.notificationMethod == 'notification') {
                    data.method = 'popup';
                    if (this.notificationTime == 'minutes') {
                        data.value = this.notificationTimeBefore.toString();
                        data.minutes = +this.notificationTimeBefore;
                        data.label = this.getTradC('DialogNotAllDayNotification.minutesNumber', this.notificationTimeBefore);
                    } else if (this.notificationTime == 'hours') {
                        data.value = (this.notificationTimeBefore * 60).toString();
                        data.minutes = +this.notificationTimeBefore * 60;
                        data.label = this.getTradC('DialogNotAllDayNotification.hoursNumber', this.notificationTimeBefore);
                    } else if (this.notificationTime == 'days') {
                        data.value = (this.notificationTimeBefore * 24 * 60).toString();
                        data.minutes = +this.notificationTimeBefore * 24 * 60;
                        data.label = this.getTradC('DialogNotAllDayNotification.daysNumber', this.notificationTimeBefore);
                    } else if (this.notificationTime == 'weeks') {
                        let oneWeekTime = 7 * 24 * 60;
                        data.value = (oneWeekTime * this.notificationTimeBefore).toString();
                        data.minutes = oneWeekTime * +this.notificationTimeBefore;
                        data.label = this.getTradC('DialogNotAllDayNotification.weeksNumber', this.notificationTimeBefore);
                    }
                } else if (this.notificationMethod == 'email') {
                    data.method = 'email';

                    if (this.notificationTime == 'minutes') {
                        data.value = this.notificationTimeBefore.toString();
                        data.minutes = +this.notificationTimeBefore;
                        data.label = this.getTradC('DialogNotAllDayNotification.minutesNumber', this.notificationTimeBefore) + this.getTrad('DialogNotAllDayNotification.byEmail');
                    } else if (this.notificationTime == 'hours') {
                        data.value = (this.notificationTimeBefore * 60).toString();
                        data.minutes = +this.notificationTimeBefore * 60;
                        data.label = this.getTradC('DialogNotAllDayNotification.hoursNumber', this.notificationTimeBefore) + this.getTrad('DialogNotAllDayNotification.byEmail');
                    } else if (this.notificationTime == 'days') {
                        data.value = (this.notificationTimeBefore * 24 * 60).toString();
                        data.minutes = +this.notificationTimeBefore * 24 * 60;
                        data.label = this.getTradC('DialogNotAllDayNotification.daysNumber', this.notificationTimeBefore) + this.getTrad('DialogNotAllDayNotification.byEmail');
                    } else if (this.notificationTime == 'weeks') {
                        let oneWeekTime = 7 * 24 * 60;
                        data.value = (oneWeekTime * this.notificationTimeBefore).toString();
                        data.minutes = oneWeekTime * +this.notificationTimeBefore;
                        data.label = this.getTradC('DialogNotAllDayNotification.weeksNumber', this.notificationTimeBefore) + this.getTrad('DialogNotAllDayNotification.byEmail');
                    }
                }

                this.$emit('getCustomNotification', data);
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                this.isActiveDialog = newVal;
            },
        },

        mounted() {
            this.notificationMethodArray[0].label = this.getTrad('DialogNotAllDayNotification.notification');
            this.notificationMethodArray[1].label = this.getTrad('DialogNotAllDayNotification.email');
            this.notificationTimeArray[0].label = this.getTrad('DialogNotAllDayNotification.minutes');
            this.notificationTimeArray[1].label = this.getTrad('DialogNotAllDayNotification.hours');
            this.notificationTimeArray[2].label = this.getTrad('DialogNotAllDayNotification.days');
            this.notificationTimeArray[3].label = this.getTrad('DialogNotAllDayNotification.weeks');
        },
    };
</script>

<style lang="scss" scoped>
    .select {
        max-width: 60px;
    }
</style>
