import axios from '@axios';
const queryString = require('qs');

/* export function listEvents(calendarId, timeMin, timeMax) {
    return axios.get(`/google-calendar/calendars/${calendarId}/events?singleEvents=true&orderBy=starttime&timeMin=${timeMin}&timeMax=${timeMax}`);
}
 */
export function listEvents(query = {}) {
    let _query = queryString.stringify(query);
    return axios.get('/google-calendar/calendars/events?' + _query);
}

export function addEvent(query = {}, calendarId, data) {
    let _query = queryString.stringify(query);
    return axios.post('google-calendar/calendars/' + calendarId + '/events?' + _query, data);
}

export function updateEvent(query = {}, calendarId, eventId, data) {
    let _query = queryString.stringify(query);
    return axios.put(`google-calendar/calendars/${calendarId}/events/${eventId}?` + _query, data);
}

export function deleteEvent(calendarId, eventId) {
    return axios.delete(`google-calendar/calendars/${calendarId}/events/${eventId}`);
}

export function getEventById(calendarId, eventId) {
    return axios.get(`google-calendar/calendars/${calendarId}/events/${eventId}`);
}
