<template>
    <div class="h-full" :key="key">
        <div class="drawer-header d-flex align-center mb-4">
            <span class="font-weight-semibold text-base text--primary">{{ eventLocal.id ? getTrad('CreateEvent.edit') : getTrad('CreateEvent.create') }} {{ getTrad('CreateEvent.event') }}</span>
            <v-spacer></v-spacer>

            <v-btn icon small @click="closeDrawer()">
                <v-icon size="22">
                    {{ icons.mdiClose }}
                </v-icon>
            </v-btn>
        </div>
        <perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-calendar-event-handler">
            <ValidationObserver ref="refCalendarEventHandlerForm" v-slot="{ handleSubmit, reset }">
                <v-form class="px-5 pt-3 pb-10" @submit.prevent="handleSubmit(onSubmit)" :disabled="disableForm">
                    <ValidationProvider :name="getTrad('CreateEvent.name')" rules="required" v-slot="{ errors }">
                        <v-text-field
                            v-model="eventLocal.name"
                            :label="getTrad('CreateEvent.name')"
                            :placeholder="getTrad('CreateEvent.name')"
                            outlined
                            :error-messages="errors"
                            hide-details="auto"
                            dense
                            class="mb-6"></v-text-field>
                    </ValidationProvider>

                    <ValidationProvider :name="getTrad('CreateEvent.calendar')" rules="required" v-slot="{ errors }">
                        <v-select
                            v-model="eventLocal.calendar"
                            outlined
                            :label="getTrad('CreateEvent.calendar')"
                            :placeholder="getTrad('CreateEvent.calendar')"
                            :item-text="(item) => item.label"
                            :item-value="(item) => item.id"
                            :items="filteredCalendars"
                            hide-details="auto"
                            :menu-props="{ offsetY: true }"
                            :error-messages="errors"
                            dense
                            class="mb-6">
                            <!-- Selected Item Slot -->
                            <template #selection="{ item }">
                                <div class="d-flex align-center">
                                    <v-badge inline :color="item.color" dot class="me-2"></v-badge>
                                    <span>{{ item.label }}</span>
                                </div>
                            </template>

                            <!-- Options Slot -->
                            <template #item="{ item }">
                                <div class="d-flex align-center">
                                    <v-badge inline :color="item.color" dot class="me-2 mb-1"></v-badge>
                                    <span>{{ item.label }}</span>
                                </div>
                            </template>
                        </v-select>
                    </ValidationProvider>

                    <v-checkbox v-model="eventLocal.timed" :label="getTrad('CreateEvent.allDay')"></v-checkbox>

                    <v-menu v-model="isStartDateMenuOpen" :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider :name="getTrad('CreateEvent.startDate')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="eventLocal.start"
                                    :label="getTrad('CreateEvent.startDate')"
                                    readonly
                                    outlined
                                    dense
                                    class="mb-6"
                                    hide-details="auto"
                                    v-bind="attrs"
                                    :error-messages="errors"
                                    v-on="on"></v-text-field>
                            </ValidationProvider>
                        </template>
                        <v-date-picker v-model="eventLocal.start" @input="isStartDateMenuOpen = false"></v-date-picker>
                    </v-menu>

                    <v-menu v-model="isEndDateMenuOpen" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider :name="getTrad('CreateEvent.endDate')" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="eventLocal.end"
                                    :label="getTrad('CreateEvent.endDate')"
                                    readonly
                                    outlined
                                    dense
                                    class="mb-6"
                                    hide-details="auto"
                                    :error-messages="errors"
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </ValidationProvider>
                        </template>
                        <v-date-picker v-model="eventLocal.end" :min="eventLocal.start" @input="isEndDateMenuOpen = false"></v-date-picker>
                    </v-menu>

                    <v-item-group v-if="!eventLocal.timed">
                        <v-container grid-list-md pa-0>
                            <v-layout wrap>
                                <v-flex xs5>
                                    <ValidationProvider :name="getTrad('CreateEvent.startTime')" rules="required" v-slot="{ errors }">
                                        <v-select
                                            dense
                                            outlined
                                            :label="getTrad('CreateEvent.startTime')"
                                            v-model="startHour"
                                            @change="getEndHoursArray"
                                            :items="startHoursArray"
                                            :error-messages="errors">
                                        </v-select>
                                    </ValidationProvider>
                                </v-flex>

                                <v-flex xs5>
                                    <ValidationProvider :name="getTrad('CreateEvent.endTime')" rules="required" v-slot="{ errors }">
                                        <v-select dense outlined :label="getTrad('CreateEvent.endTime')" v-model="endHour" no-filter :items="endHoursArray" :error-messages="errors"> </v-select>
                                    </ValidationProvider>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-item-group>

                    <v-select
                        outlined
                        :label="getTrad('CreateEvent.repetition')"
                        :placeholder="getTrad('CreateEvent.repetition')"
                        item-text="label"
                        item-value="value"
                        :items="repetition"
                        hide-details="auto"
                        :menu-props="{ offsetY: true }"
                        dense
                        class="mb-6"
                        v-model="repetitionSelected"
                        @change="openDialogRepetitionHandler(repetitionSelected)">
                    </v-select>

                    <v-btn class="mb-6 primary" small v-if="!hasMeeting" @click="changeMeetingStatus" :loading="meetingLoading" :disabled="disableForm">
                        {{ getTrad('CreateEvent.addMeeting') }}
                    </v-btn>
                    <v-btn class="mb-6 primary" small v-else @click="changeMeetingStatus" :loading="meetingLoading" :disabled="disableForm"> {{ getTrad('CreateEvent.removeMeeting') }} </v-btn>

                    <v-autocomplete
                        v-model="eventLocal.guests"
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        dense
                        color="blue-grey lighten-2"
                        class="mb-6 select-guest"
                        :label="getTrad('CreateEvent.invite')"
                        :placeholder="getTrad('CreateEvent.invite')"
                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                        :item-text="(guest) => guest.name"
                        :item-value="(guest) => guest.email"
                        :items="$store.getters['google-calendar/calendar/getContacts']"
                        hide-details="auto">
                        <template v-slot:selection="data">
                            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                                <!-- DESCOMENTAR PARA MOSTRAR FOTOS>
                              <!--   <v-avatar left>
                                    <v-img v-if="data.item.photo" :src="data.item.photo" referrerpolicy="no-referrer"></v-img>
                                    <v-icon v-else size="30" color="primary "> {{ icons.mdiAccount }}</v-icon>
                                </v-avatar> -->
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template #item="{ item }">
                            <div class="d-flex align-center">
                                <!-- DESCOMENTAR PARA MOSTRAR FOTOS>
                               <!--  <v-avatar size="25" class="me-2">
                                    <v-img v-if="item.photo" :src="item.photo" referrerpolicy="no-referrer"></v-img>
                                    <v-icon v-else size="30" color="primary "> {{ icons.mdiAccount }}</v-icon>
                                </v-avatar> -->
                                <span class="text-sm" v-if="item.name">{{ item.name }}</span>
                                <span class="text-sm" v-else>{{ item.email }}</span>
                            </div>
                        </template>
                    </v-autocomplete>

                    <!-- <v-select
                        v-model="eventLocal.guests"
                        outlined
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        dense
                        class="mb-6 select-guest"
                        hide-details="auto"
                        :label="getTrad('CreateEvent.invite')"
                        :placeholder="getTrad('CreateEvent.invite')"
                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                        :item-text="(guest) => guest.name"
                        :item-value="(guest) => guest.email"
                        :items="$store.getters['google-calendar/calendar/getContacts']">
                        <template #item="{ item }">
                            <div class="d-flex align-center">
                                <v-avatar size="25" class="me-2">
                                    <v-img v-if="item.photo" :src="item.photo" referrerpolicy="no-referrer"></v-img>
                                    <v-icon v-else size="30" color="primary "> {{ icons.mdiAccount }}</v-icon>
                                </v-avatar>
                                <span class="text-sm" v-if="item.name">{{ item.name }}</span>
                                <span class="text-sm" v-else>{{ item.email }}</span>
                            </div>
                        </template>
                    </v-select> -->

                    <v-text-field
                        v-model="eventLocal.location"
                        :label="getTrad('CreateEvent.location')"
                        dense
                        class="mb-6"
                        hide-details="auto"
                        :placeholder="getTrad('CreateEvent.location')"
                        outlined></v-text-field>

                    <v-textarea v-model="eventLocal.description" auto-grow outlined :label="getTrad('CreateEvent.description')" :placeholder="getTrad('CreateEvent.description')"></v-textarea>

                    <v-row class="mb-6">
                        <v-col cols="1">
                            <v-icon>{{ icons.mdiBell }}</v-icon></v-col
                        >
                        <v-col cols="11" v-if="eventLocal.timed">
                            <div class="d-flex align-center justify-center mt-1" v-for="(notification, index) in notificationsAllDaySelectedArray" :key="index">
                                <v-select
                                    v-model="notificationAllDaySelected[index]"
                                    item-text="label"
                                    return-object
                                    :items="notificationAllDay"
                                    hide-details="auto"
                                    :menu-props="{ offsetY: true }"
                                    class="ml-2 mb-2"
                                    dense
                                    outlined
                                    @change="openDialogNotificationHandler(notificationAllDaySelected[index], index)">
                                </v-select>
                                <v-icon size="22" class="ml-4" style="cursor: pointer" @click="removeNotificationHandler(index)">
                                    {{ icons.mdiClose }}
                                </v-icon>
                            </div>
                            <h4 class="ml-2" style="cursor: pointer" @click="addNotificationArrayHandler" v-if="notificationAllDaySelected.length < 5">Adicionar notificação</h4>
                        </v-col>
                        <v-col cols="11" v-else>
                            <div class="d-flex align-center justify-center mt-1" v-for="(notification, index) in notificationsNotAllDaySelectedArray" :key="index">
                                <v-select
                                    v-model="notificationNotAllDaySelected[index]"
                                    item-text="label"
                                    return-object
                                    :items="notificationNotAllDay"
                                    hide-details="auto"
                                    :menu-props="{ offsetY: true }"
                                    class="ml-2 mb-2"
                                    dense
                                    outlined
                                    @change="openDialogNotificationHandler(notificationNotAllDaySelected[index], index)">
                                </v-select>
                                <v-icon size="22" class="ml-4" style="cursor: pointer" @click="removeNotificationHandler(index)">
                                    {{ icons.mdiClose }}
                                </v-icon>
                            </div>

                            <h4 class="ml-2" style="cursor: pointer" @click="addNotificationArrayHandler" v-if="notificationNotAllDaySelected.length < 5">
                                {{ getTrad('CreateEvent.addNotification') }}
                            </h4>
                        </v-col>
                    </v-row>

                    <v-btn outlined class="me-3" type="reset" @click="onCancel" :loading="isLoading"> {{ getTrad('CreateEvent.clear') }} </v-btn>

                    <v-btn color="primary" type="submit" :loading="isLoading">
                        {{ eventLocal.id ? getTrad('CreateEvent.edit') : getTrad('CreateEvent.create') }}
                    </v-btn>
                </v-form>
            </ValidationObserver>
        </perfect-scrollbar>
        <DialogNotAllDay :isActive="openDialogNotAllDay" @closeDialog="closeDialogNotAllDayHandler" @getCustomNotification="getCustomNotAllDayNotification" />

        <DialogAllDay :isActive="openDialogAllDay" @closeDialog="closeDialogAllDayHandler" @getCustomNotification="getCustomAllDayNotification" />

        <DialogRepetition :eventStart="eventLocal.start" :isActive="openDialogRepetition" @closeDialog="closeRepetitionDialogHandler" @getCustomRepetition="getCustomRepetition" />
    </div>
</template>

<script>
    import { ref, watch } from '@vue/composition-api';
    import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

    import { mdiTrashCanOutline, mdiClose, mdiBell, mdiAccount } from '@mdi/js';
    import DialogNotAllDay from '../../components/DialogNotAllDayNotification.vue';
    import DialogAllDay from '../../components/DialogAllDayNotification.vue';
    import DialogRepetition from '../../components/RepetitionDialog.vue';

    import getTrad from '../../i18n/getTrad';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import moment from 'moment';
    import 'moment/locale/en-gb';

    import { addEvent, updateEvent } from '@/api/events';

    export default {
        mixins: [getTrad],
        components: {
            PerfectScrollbar,
            DialogNotAllDay,
            DialogAllDay,
            DialogRepetition,
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            event: {
                type: Object,
                required: true,
            },
            clearEventData: {
                type: Function,
                required: true,
            },
            filteredCalendars: {
                type: Array,
            },
        },

        data() {
            return {
                startHoursArray: [],
                endHoursArray: [],
                repetition: [
                    { value: 'none', label: '' },
                    { value: 'DAILY', label: '' },
                    { value: 'WEEKLY', label: '' },
                    { value: 'MONTHLY', label: '' },
                    { value: 'YEARLY', label: '' },
                    { value: 'WEEKDAY', label: '' },
                    { value: 'custom', label: '' },
                ],
                notificationNotAllDay: [
                    { value: 'fiveMinutes', label: '', method: 'popup', minutes: 5 },
                    { value: 'tenMinutes', label: '', method: 'popup', minutes: 10 },
                    { value: 'fifteenMinutes', label: '', method: 'popup', minutes: 15 },
                    { value: 'thirtyMinutes', label: '', method: 'popup', minutes: 30 },
                    { value: 'oneHour', label: '', method: 'popup', minutes: 60 },
                    { value: 'oneDay', label: '', method: 'popup', minutes: 1440 },
                    { value: 'custom', label: '' },
                ],
                notificationAllDay: [
                    { value: 'sameDay', label: '', method: 'popup', minutes: 500 },
                    { value: 'dayBefore', label: '', method: 'popup', minutes: 900 },
                    { value: 'twoDaysBefore', label: '', method: 'popup', minutes: 2340 },
                    { value: 'oneWeekBefore', label: '', method: 'popup', minutes: 9540 },
                    { value: 'custom', label: '' },
                ],
                startHour: null,
                endHour: null,

                notificationsNotAllDaySelectedArray: [0],
                notificationsAllDaySelectedArray: [1],

                notificationNotAllDaySelected: [],
                notificationAllDaySelected: [],
                repetitionSelected: 'none',

                openDialogAllDay: false,
                openDialogNotAllDay: false,
                openDialogRepetition: false,

                personalizedNotAllDayIndex: null,
                personalizedAllDayIndex: null,

                originalRepetitionArrayLength: 0,
                originalNotificationNotAllDayArrayLength: 0,
                originalNotificationAllDayArrayLength: 0,

                icons: {
                    mdiAccount,
                    mdiBell,
                    mdiClose,
                },

                updatedOnce: false,
                hasMeeting: false,
                meetingLoading: false,
                disableForm: false,
                isLoading: false,
                key: 0,
            };
        },

        methods: {
            addNotificationArrayHandler() {
                if (this.eventLocal.timed) {
                    this.notificationsAllDaySelectedArray.push(1);
                    this.notificationAllDaySelected.push('sameDay');
                } else {
                    this.notificationsNotAllDaySelectedArray.push(0);
                    this.notificationNotAllDaySelected.push('fiveMinutes');
                }
                console.log(this.notificationsAllDaySelectedArray);
                console.log(this.notificationsNotAllDaySelectedArray);
            },

            removeNotificationHandler(index) {
                if (this.eventLocal.timed) {
                    this.notificationsAllDaySelectedArray.splice(index, 1);
                    this.notificationAllDaySelected.splice(index, 1);
                } else {
                    this.notificationsNotAllDaySelectedArray.splice(index, 1);
                    this.notificationNotAllDaySelected.splice(index, 1);
                }
            },

            openDialogNotificationHandler(obj, index) {
                console.log(obj);
                if (obj.value == 'custom' && this.eventLocal.timed) {
                    this.personalizedAllDayIndex = index;
                    this.openDialogAllDay = true;
                } else if (obj.value == 'custom' && !this.eventLocal.timed) {
                    this.personalizedNotAllDayIndex = index;
                    this.openDialogNotAllDay = true;
                }
            },

            closeDialogNotAllDayHandler() {
                this.openDialogNotAllDay = false;
                this.notificationNotAllDaySelected[this.personalizedNotAllDayIndex] = this.notificationNotAllDay[0];
            },

            closeDialogAllDayHandler() {
                this.openDialogAllDay = false;

                this.notificationAllDaySelected[this.personalizedAllDayIndex] = this.notificationAllDay[0];

                this.key++;
                console.log(this.key);
            },

            getCustomNotAllDayNotification(data) {
                this.openDialogNotAllDay = false;

                this.notificationNotAllDay.splice(this.notificationNotAllDay.length - 1, 0, {
                    value: data.value,
                    label: data.label,
                    method: data.method,
                    minutes: data.minutes,
                });

                this.notificationNotAllDaySelected[this.personalizedNotAllDayIndex] = this.notificationNotAllDay[this.notificationNotAllDay.length - 2];
            },

            getCustomAllDayNotification(data) {
                this.openDialogAllDay = false;

                this.notificationAllDay.splice(this.notificationAllDay.length - 1, 0, {
                    value: data.value,
                    label: data.label,
                    method: data.method,
                    minutes: data.minutes,
                });

                this.notificationAllDaySelected[this.personalizedAllDayIndex] = this.notificationAllDay[this.notificationAllDay.length - 2];
            },

            openDialogRepetitionHandler(value) {
                if (value == 'custom') {
                    this.openDialogRepetition = true;
                }
            },

            closeRepetitionDialogHandler() {
                this.openDialogRepetition = false;
                this.repetitionSelected = 'none';
            },

            getCustomRepetition(data) {
                this.openDialogRepetition = false;

                if (this.originalRepetitionArrayLength == this.repetition.length) {
                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                this.repetitionSelected = data.value;
            },

            createHoursArray(array) {
                var x = 15; //minutes interval

                var tt = 0; // start time

                //loop to increment the time and push results in array
                for (var i = 0; tt < 24 * 60; i++) {
                    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format

                    var mm = tt % 60; // getting minutes of the hour in 0-55 format

                    array[i] = ('0' + (hh % 12)).slice(-2) == '00' && i + 1 == 13 ? '12' + ':' + ('0' + mm).slice(-2) : hh + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]

                    tt = tt + x;
                }
            },
            getEndHoursArray() {
                this.createHoursArray(this.endHoursArray);

                const findIndex = this.endHoursArray.findIndex((hour) => hour == this.startHour);
                this.endHoursArray.splice(0, findIndex);
            },

            onCancel() {
                this.$refs.refCalendarEventHandlerForm.reset();
            },

            onSubmit() {
                console.log(this.eventLocal.start);
                this.disableForm = true;
                this.isLoading = true;
                let attendees = [];
                if (this.eventLocal.guests) {
                    this.eventLocal.guests.map((guest) => {
                        attendees.push({ email: guest });
                    });
                }

                let recurrence;
                if (this.repetitionSelected == 'WEEKDAY') {
                    recurrence = `RRULE:FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR`;
                } else if (this.repetitionSelected == 'DAILY' || this.repetitionSelected == 'WEEKLY' || this.repetitionSelected == 'YEARLY') {
                    recurrence = `RRULE:FREQ=${this.repetitionSelected}`;
                } else if (this.repetitionSelected == 'MONTHLY') {
                    let weekDay = moment(this.eventLocal.start).day();
                    let weekDate = moment().isoWeekday(weekDay);
                    let dayStr = moment(weekDate._d).locale('en').format('dd').toUpperCase();
                    let weekNumber = Math.ceil(moment(this.eventLocal.start).date() / 7);
                    recurrence = `RRULE:FREQ=MONTHLY;BYDAY=${dayStr};BYSETPOS=${weekNumber}`;
                } else recurrence = this.repetitionSelected;

                let notifications = [];
                if (!this.eventLocal.timed) {
                    this.notificationNotAllDaySelected.map((notification) => {
                        console.log(notification);
                        notifications.push({
                            method: notification.method,
                            minutes: notification.minutes,
                        });
                    });
                } else {
                    this.notificationAllDaySelected.map((notification) => {
                        console.log(notification);
                        notifications.push({
                            method: notification.method,
                            minutes: notification.minutes,
                        });
                    });
                }

                let data = {
                    summary: this.eventLocal.name,
                    location: this.eventLocal.location,
                    description: this.eventLocal.description,
                    start: {
                        ...(this.eventLocal.timed ? { date: this.eventLocal.start } : { dateTime: this.eventLocal.start + 'T' + this.startHour + ':00' }),

                        timeZone: this.verifyTimeZone(this.eventLocal.calendar),
                    },
                    end: {
                        ...(this.eventLocal.timed ? { date: this.eventLocal.end } : { dateTime: this.eventLocal.end + 'T' + this.endHour + ':00' }),
                        timeZone: this.verifyTimeZone(this.eventLocal.calendar),
                    },

                    attendees: attendees,
                    ...(recurrence != 'none' && { recurrence: [recurrence] }),

                    ...(this.hasMeeting && {
                        conferenceData: {
                            createRequest: {
                                conferenceSolutionKey: {
                                    type: 'hangoutsMeet',
                                },
                                requestId: 'somerequestid',
                            },
                        },
                    }),

                    ...(notifications.length > 0 && {
                        reminders: {
                            useDefault: false,
                            overrides: notifications,
                        },
                    }),
                };

                console.log(data);

                let query = {
                    sendUpdates: 'all',
                    ...(this.hasMeeting && { conferenceDataVersion: 1 }),
                };

                console.log(this.eventLocal);

                if (!this.eventLocal.id) {
                    addEvent(query, this.eventLocal.calendar, data)
                        .then((response) => {
                            console.log(response);
                            this.hasMeeting = false;

                            this.$refs.refCalendarEventHandlerForm.reset();
                            this.notificationNotAllDaySelected.push(this.notificationNotAllDay[0]);
                            this.notificationAllDaySelected.push(this.notificationAllDay[0]);
                            this.repetitionSelected = 'none';
                            this.$emit('sync-events', false, null);
                            this.$emit('close-drawer');
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$snackbar({
                                text: this.getTrad('CreateEvent.errorText'),
                                color: 'error',
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            this.disableForm = false;
                            this.isLoading = false;
                        });
                } else {
                    updateEvent(query, this.eventLocal.calendar, this.eventLocal.id, data)
                        .then((response) => {
                            console.log(response);
                            this.hasMeeting = false;

                            this.$emit('sync-events', true, this.eventLocal.id);
                            this.$emit('close-drawer');
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$snackbar({
                                text: this.getTrad('CreateEvent.errorText'),
                                color: 'error',
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            this.disableForm = false;
                            this.isLoading = false;
                        });
                }
            },

            verifyTimeZone(calendarId) {
                let timeZone;
                this.$store.getters['google-calendar/calendar/getCalendarOptions'].filter((calendar) => {
                    if (calendar.id == calendarId) {
                        timeZone = calendar.timeZone;
                    }
                });
                console.log(timeZone);
                return timeZone;
            },

            changeMeetingStatus() {
                this.meetingLoading = true;
                setTimeout(() => {
                    this.hasMeeting = !this.hasMeeting;
                    this.meetingLoading = false;
                }, 1500);
            },

            closeDrawer() {
                this.notificationNotAllDaySelected = [];
                this.notificationAllDaySelected = [];
                this.notificationNotAllDaySelected.push(this.notificationNotAllDay[0]);
                this.notificationAllDaySelected.push(this.notificationAllDay[0]);
                console.log(this.notificationNotAllDaySelected);
                console.log(this.notificationAllDaySelected);
                this.repetitionSelected = 'none';
                this.$refs.refCalendarEventHandlerForm.reset();
                this.$emit('close-drawer');
            },

            computedDateFormattedMomentjs(date) {
                moment.locale('pt-pt');
                return moment(date).format('D MMM YYYY');
            },
            computedDayDate(date) {
                moment.locale('pt-pt');
                return moment(date).format('D');
            },

            verifySplittedWords(array) {
                let repetitionFrequency;
                let count;
                let date;
                let daysString = '';
                let countWeekDays;
                let weekNumber;

                array.map((word) => {
                    if (word.includes('INTERVAL')) {
                        let split = word.split('=');
                        repetitionFrequency = split[1];
                    }

                    if (word.includes('COUNT')) {
                        let split = word.split('=');
                        count = split[1];
                    }

                    if (word.includes('UNTIL')) {
                        let split = word.split('=');
                        date = split[1];
                    }
                    if (word.includes('BYDAY')) {
                        let split = word.split('=');

                        if (split[1].length > 2) {
                            weekNumber = split[1].charAt(0);
                            split[1] = split[1].substring(1);
                        }

                        let splittedDays = split[1].split(',');

                        countWeekDays = splittedDays.length;

                        for (let i = 0; i < splittedDays.length; i++) {
                            switch (splittedDays[i]) {
                                case 'SU':
                                    daysString += this.getTrad('RepetitionDialog.sunday') + ', ';
                                    break;

                                case 'MO':
                                    daysString += this.getTrad('RepetitionDialog.monday') + ', ';
                                    break;
                                case 'TU':
                                    daysString += this.getTrad('RepetitionDialog.tuesday') + ', ';
                                    break;
                                case 'WE':
                                    daysString += this.getTrad('RepetitionDialog.wednesday') + ', ';
                                    break;
                                case 'TH':
                                    daysString += this.getTrad('RepetitionDialog.thursday') + ', ';
                                    break;
                                case 'FR':
                                    daysString += this.getTrad('RepetitionDialog.friday') + ', ';
                                    break;
                                case 'SA':
                                    daysString += this.getTrad('RepetitionDialog.saturday') + ', ';
                                    break;
                            }
                        }
                    }
                });
                daysString = daysString.replace(/,\s*$/, '');
                return (data = {
                    repetitionFrequency: repetitionFrequency,
                    count: count,
                    date: date,
                    days: daysString,
                    countWeekDays: countWeekDays,
                    weekNumber: weekNumber,
                });
            },
        },

        watch: {
            event: {
                handler: function (val) {
                    console.log(val);
                    if ('roundedHour' in val) {
                        this.startHour = val.roundedHour;
                        this.getEndHoursArray();
                    } else this.startHour = null;

                    if ('isEditting' in val) {
                        this.startHour = val.startHour;
                        this.getEndHoursArray();
                        this.endHour = val.endHour;

                        if (val.recurrence) {
                            let splitWords = val.recurrence.split(';');
                            let data = {
                                value: val.recurrence,
                                label: '',
                            };

                            //VARIABLES TO USE IN CUSTOM SELECT OPTION

                            let repetitionFrequency = this.verifySplittedWords(splitWords).repetitionFrequency;
                            let count = this.verifySplittedWords(splitWords).count;
                            let days = this.verifySplittedWords(splitWords).days;
                            let countWeekDays = this.verifySplittedWords(splitWords).countWeekDays;
                            let date = this.verifySplittedWords(splitWords).date;
                            let weekNumber = this.verifySplittedWords(splitWords).weekNumber;

                            //VERIFY DAILY
                            if (val.recurrence.includes('DAILY') && !val.recurrence.includes('INTERVAL') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                this.repetitionSelected = 'DAILY';
                            } else if (val.recurrence.includes('DAILY') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                data.label =
                                    repetitionFrequency == 1
                                        ? this.getTrad('RepetitionDialog.everyDay')
                                        : this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.days');
                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            } else if (val.recurrence.includes('DAILY') && val.recurrence.includes('COUNT')) {
                                data.label =
                                    repetitionFrequency == 1 || repetitionFrequency == undefined
                                        ? this.getTrad('RepetitionDialog.everyDay') + this.getTradC('RepetitionDialog.times', count)
                                        : this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.days') + this.getTradC('RepetitionDialog.times', count);

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            } else if (val.recurrence.includes('DAILY') && val.recurrence.includes('UNTIL')) {
                                data.label =
                                    repetitionFrequency == 1 || repetitionFrequency == undefined
                                        ? this.getTrad('RepetitionDialog.everyDayUntil') + this.computedDateFormattedMomentjs(date)
                                        : this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.daysUntil') + this.computedDateFormattedMomentjs(date);

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;

                                //VERIFY WEEKLY
                            } else if (val.recurrence.includes('WEEKLY') && !val.recurrence.includes('INTERVAL') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                if (countWeekDays.length == 1) {
                                    this.repetitionSelected = 'WEEKLY';
                                } else {
                                    if (countWeekDays == 7) {
                                        data.label = this.getTrad('RepetitionDialog.weeklyEveryDay');
                                    } else data.label = this.getTrad('RepetitionDialog.weekly') + days;

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                }
                            } else if (val.recurrence.includes('WEEKLY') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                if (countWeekDays.length == 1) {
                                    this.repetitionSelected = 'WEEKLY';
                                } else {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        if (countWeekDays == 7) {
                                            data.label = this.getTrad('RepetitionDialog.weeklyEveryDay');
                                        } else data.label = this.getTrad('RepetitionDialog.weekly') + days;
                                    } else {
                                        if (countWeekDays == 7) {
                                            data.label = this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.weeksEveryDay');
                                        } else data.label = this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.weeks') + days;
                                    }

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                }
                            } else if (val.recurrence.includes('WEEKLY') && val.recurrence.includes('COUNT')) {
                                if (repetitionFrequency == 1) {
                                    if (countWeekDays == 7) {
                                        data.label = this.getTrad('RepetitionDialog.weeklyEveryDay') + this.getTradC('RepetitionDialog.times', count);
                                    } else data.label = this.getTrad('RepetitionDialog.weekly') + days + this.getTradC('RepetitionDialog.times', count);
                                } else {
                                    if (countWeekDays == 7) {
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.weeksEveryDayUntil') +
                                            this.getTradC('RepetitionDialog.times', count);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.weeks') +
                                            days +
                                            this.getTrad('RepetitionDialog.until') +
                                            this.getTradC('RepetitionDialog.times', count);
                                }
                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            } else if (val.recurrence.includes('WEEKLY') && val.recurrence.includes('UNTIL')) {
                                if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                    if (countWeekDays == 7) {
                                        data.label = this.getTrad('RepetitionDialog.weeklyEveryDayUntil') + this.computedDateFormattedMomentjs(date);
                                    } else data.label = this.getTrad('RepetitionDialog.weekly') + days + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs(date);
                                } else {
                                    if (countWeekDays == 7) {
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.weeklyEveryDayUntilLower') +
                                            this.computedDateFormattedMomentjs(date);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.weeks') +
                                            days +
                                            this.getTrad('RepetitionDialog.until') +
                                            this.computedDateFormattedMomentjs(date);
                                }

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;

                                //VERIFY MONTHLY
                            } else if (val.recurrence.includes('MONTHLY') && !val.recurrence.includes('BYDAY')) {
                                if (!val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label = this.getTrad('RepetitionDialog.monthlyAt') + this.computedDayDate(val.start);
                                    } else data.label = this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.monthsAt') + this.computedDayDate(val.start);

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                } else if (val.recurrence.includes('COUNT')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label = this.getTrad('RepetitionDialog.monthlyAt') + this.computedDayDate(val.start) + ', ' + this.getTradC('RepetitionDialog.times', count);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.monthsAt') +
                                            this.computedDayDate(val.start) +
                                            ', ' +
                                            this.getTradC('RepetitionDialog.times', count);

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                } else if (val.recurrence.includes('UNTIL')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label =
                                            this.getTrad('RepetitionDialog.monthlyAt') +
                                            this.computedDayDate(val.start) +
                                            this.getTrad('RepetitionDialog.until') +
                                            this.computedDateFormattedMomentjs(date);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.monthsAt') +
                                            this.computedDayDate(val.start) +
                                            this.getTrad('RepetitionDialog.until') +
                                            this.computedDateFormattedMomentjs(date);

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                }
                            } else if (val.recurrence.includes('MONTHLY') && val.recurrence.includes('BYDAY')) {
                                let weekRef;
                                if (weekNumber == 1) {
                                    weekRef = this.getTrad('RepetitionDialog.monthlyFirst') + moment(val.start).format('dddd');
                                } else if (weekNumber == 2) {
                                    weekRef = this.getTrad('RepetitionDialog.monthlySecond') + moment(val.start).format('dddd');
                                } else if (weekNumber == 3) {
                                    weekRef = this.getTrad('RepetitionDialog.monthlyThird') + moment(val.start).format('dddd');
                                } else if (weekNumber == 4) {
                                    weekRef = this.getTrad('RepetitionDialog.monthlyFourth') + moment(val.start).format('dddd');
                                } else weekRef = this.getTrad('RepetitionDialog.monthlyLast') + moment(val.start).format('dddd');

                                if (!val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label = weekRef;
                                    } else data.label = this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.months') + weekRef.toLowerCase();

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                } else if (val.recurrence.includes('COUNT')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label = weekRef + ', ' + this.getTradC('RepetitionDialog.times', count);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.months') +
                                            ', ' +
                                            weekRef.toLowerCase() +
                                            ', ' +
                                            this.getTradC('RepetitionDialog.times', count);

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                } else if (val.recurrence.includes('UNTIL')) {
                                    if (repetitionFrequency == 1 || repetitionFrequency == undefined) {
                                        data.label = weekRef + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs(date);
                                    } else
                                        data.label =
                                            this.getTrad('RepetitionDialog.every') +
                                            repetitionFrequency +
                                            this.getTrad('RepetitionDialog.months') +
                                            ', ' +
                                            weekRef.toLowerCase() +
                                            this.getTrad('RepetitionDialog.until') +
                                            this.computedDateFormattedMomentjs(date);

                                    if (this.originalRepetitionArrayLength == this.repetition.length) {
                                        this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                    } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                    this.repetitionSelected = data.value;
                                }
                            }
                            //VERIFY YEARLY
                            else if (val.recurrence.includes('YEARLY') && !val.recurrence.includes('INTERVAL') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                this.repetitionSelected = 'YEARLY';
                            } else if (val.recurrence.includes('YEARLY') && !val.recurrence.includes('COUNT') && !val.recurrence.includes('UNTIL')) {
                                data.label =
                                    repetitionFrequency == 1 || repetitionFrequency == undefined
                                        ? this.getTrad('RepetitionDialog.annually')
                                        : this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.years');

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            } else if (val.recurrence.includes('YEARLY') && val.recurrence.includes('COUNT')) {
                                data.label =
                                    repetitionFrequency == 1 || repetitionFrequency == undefined
                                        ? this.getTrad('RepetitionDialog.annually') + this.getTradC('RepetitionDialog.times', count)
                                        : this.getTrad('RepetitionDialog.every') + repetitionFrequency + this.getTrad('RepetitionDialog.years') + this.getTradC('RepetitionDialog.times', count);

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            } else if (val.recurrence.includes('YEARLY') && val.recurrence.includes('UNTIL')) {
                                data.label =
                                    repetitionFrequency == 1 || repetitionFrequency == undefined
                                        ? this.getTrad('RepetitionDialog.annually') + this.getTrad('RepetitionDialog.until') + this.computedDateFormattedMomentjs(date)
                                        : this.getTrad('RepetitionDialog.every') +
                                          repetitionFrequency +
                                          this.getTrad('RepetitionDialog.years') +
                                          this.getTrad('RepetitionDialog.until') +
                                          this.computedDateFormattedMomentjs(date);

                                if (this.originalRepetitionArrayLength == this.repetition.length) {
                                    this.repetition.splice(this.repetition.length - 1, 0, { value: data.value, label: data.label });
                                } else this.repetition.splice(this.repetition.length - 2, 1, { value: data.value, label: data.label });

                                this.repetitionSelected = data.value;
                            }
                        }

                        if (val.reminder.useDefault == true) {
                            this.notificationNotAllDaySelected = [];
                            this.notificationsNotAllDaySelectedArray = [];
                            this.notificationsNotAllDaySelectedArray.push(0);
                            this.notificationNotAllDaySelected.push('tenMinutes');
                        } else {
                            this.notificationNotAllDaySelected = [];
                            this.notificationsNotAllDaySelectedArray = [];
                            this.notificationAllDaySelected = [];
                            this.notificationsAllDaySelectedArray = [];
                            let customArray = [];

                            if (!val.timed) {
                                val.reminder.overrides.map((reminder, reminderIndex) => {
                                    console.log(reminder);

                                    let filterOptions = this.notificationNotAllDay.filter((notification) => reminder.method == 'popup' && reminder.minutes == notification.minutes);
                                    console.log(filterOptions);

                                    if (filterOptions.length > 0) {
                                        this.notificationNotAllDaySelected.push(filterOptions[0]);
                                        this.notificationsNotAllDaySelectedArray.push(filterOptions[0].value);
                                    } else {
                                        let label;
                                        if (reminder.minutes < 60) {
                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogNotAllDayNotification.minutesNumber', reminder.minutes))
                                                : (label = this.getTradC('DialogNotAllDayNotification.minutesNumber', reminder.minutes) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        } else if (reminder.minutes >= 60 && reminder.minutes < 1440) {
                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogNotAllDayNotification.hoursNumber', reminder.minutes / 60))
                                                : (label = this.getTradC('DialogNotAllDayNotification.hoursNumber', reminder.minutes / 60) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        } else if (reminder.minutes >= 1440 && reminder.minutes < 10080) {
                                            let daysTime = reminder.minutes / 60 / 24;

                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogNotAllDayNotification.daysNumber', daysTime))
                                                : (label = this.getTradC('DialogNotAllDayNotification.daysNumber', daysTime) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        } else {
                                            let weeksTime = reminder.minutes / 7 / 24 / 60;
                                            if (!Number.isInteger(weeksTime)) {
                                                weeksTime = reminder.minutes / 60 / 24;
                                                reminder.method == 'popup'
                                                    ? (label = this.getTradC('DialogNotAllDayNotification.daysNumber', weeksTime))
                                                    : (label = this.getTradC('DialogNotAllDayNotification.daysNumber', weeksTime) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                            } else {
                                                reminder.method == 'popup'
                                                    ? (label = this.getTradC('DialogNotAllDayNotification.weeksNumber', weeksTime))
                                                    : (label = this.getTradC('DialogNotAllDayNotification.weeksNumber', weeksTime) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                            }
                                        }

                                        customArray.push({
                                            value: reminder.minutes.toString(),
                                            label: label,
                                            method: reminder.method,
                                            minutes: reminder.minutes,
                                        });
                                    }
                                });
                            } else {
                                val.reminder.overrides.map((reminder, reminderIndex) => {
                                    console.log(reminder);

                                    let filterOptions = this.notificationAllDay.filter((notification) => reminder.method == 'popup' && reminder.minutes == notification.minutes);

                                    if (filterOptions.length > 0) {
                                        this.notificationAllDaySelected.push(filterOptions[0]);
                                        this.notificationsAllDaySelectedArray.push(filterOptions[0].value);
                                    } else {
                                        let label;
                                        if (reminder.minutes >= 60 && reminder.minutes < 1440) {
                                            console.log(moment.duration(reminder.minutes, 'minutes').humanize());

                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogNotAllDayNotification.hoursNumber', reminder.minutes / 60))
                                                : (label = this.getTradC('DialogNotAllDayNotification.hoursNumber', reminder.minutes / 60) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        } else if (reminder.minutes >= 1440 && reminder.minutes < 10080) {
                                            console.log(moment.duration(reminder.minutes, 'minutes'));

                                            let daysTime = moment.duration(reminder.minutes, 'minutes').humanize();

                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogAllDayNotification.daysNumber', daysTime.split(' ')[0]))
                                                : (label = this.getTradC('DialogNotAllDayNotification.daysNumber', daysTime) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        } else {
                                            let daysTime = moment.duration(reminder.minutes, 'minutes').humanize();

                                            let numberOfWeeks = daysTime.split(' ')[0];
                                            if (numberOfWeeks > 7) {
                                                numberOfWeeks = numberOfWeeks / 7;
                                            }

                                            reminder.method == 'popup'
                                                ? (label = this.getTradC('DialogAllDayNotification.weeksNumber', numberOfWeeks))
                                                : (label = this.getTradC('DialogAllDayNotification.weeksNumber', numberOfWeeks) + this.getTrad('DialogNotAllDayNotification.byEmail'));
                                        }

                                        customArray.push({
                                            value: reminder.minutes.toString(),
                                            label: label,
                                            method: reminder.method,
                                            minutes: reminder.minutes,
                                        });
                                    }
                                });
                            }

                            if (customArray.length > 0) {
                                let reduceCustomArray = customArray.reduce((unique, o) => {
                                    if (!unique.some((obj) => obj.minutes === o.minutes && obj.method === o.method)) {
                                        unique.push(o);
                                    }

                                    return unique;
                                }, []);

                                customArray = reduceCustomArray;

                                customArray.map((notification) => {
                                    console.log(notification);
                                    if (!val.timed) {
                                        this.notificationNotAllDay.splice(this.notificationNotAllDay.length - 1, 0, {
                                            value: notification.value,
                                            label: notification.label,
                                            method: notification.method,
                                            minutes: notification.minutes,
                                        });

                                        this.notificationNotAllDaySelected.push(notification.value);
                                        this.notificationsNotAllDaySelectedArray.push(0);
                                    } else {
                                        this.notificationAllDay.splice(this.notificationAllDay.length - 1, 0, {
                                            value: notification.value,
                                            label: notification.label,
                                            method: notification.method,
                                            minutes: notification.minutes,
                                        });

                                        this.notificationAllDaySelected.push(notification.value);
                                        this.notificationsAllDaySelectedArray.push(0);
                                    }
                                });
                            }

                            console.log(this.notificationAllDaySelected);
                            console.log(this.notificationsAllDaySelectedArray);

                            this.notificationNotAllDaySelected.sort(function (a, b) {
                                return a.minutes - b.minutes;
                            });
                            this.notificationAllDaySelected.sort(function (a, b) {
                                return a.minutes - b.minutes;
                            });
                        }
                    }
                },
                deep: true,
            },
        },

        mounted() {
            this.originalRepetitionArrayLength = this.repetition.length;
            this.originalNotificationNotAllDayArrayLength = this.notificationNotAllDay.length;
            this.originalNotificationAllDayArrayLength = this.notificationAllDay.length;
            this.notificationNotAllDay[0].label = this.getTradC('CreateEvent.minutesBefore', 5);
            this.notificationNotAllDay[1].label = this.getTradC('CreateEvent.minutesBefore', 10);
            this.notificationNotAllDay[2].label = this.getTradC('CreateEvent.minutesBefore', 15);
            this.notificationNotAllDay[3].label = this.getTradC('CreateEvent.minutesBefore', 30);
            this.notificationNotAllDay[4].label = this.getTradC('CreateEvent.hoursBefore', 1);
            this.notificationNotAllDay[5].label = this.getTradC('CreateEvent.daysBefore', 1);
            this.notificationNotAllDay[6].label = this.getTrad('CreateEvent.personalized');
            this.notificationNotAllDaySelected.push(this.notificationNotAllDay[0]);

            this.notificationAllDay[0].label = this.getTrad('CreateEvent.sameDayAt');
            this.notificationAllDay[1].label = this.getTradC('CreateEvent.dayBeforeAt');
            this.notificationAllDay[2].label = this.getTradC('CreateEvent.dayBeforeAt', 2);
            this.notificationAllDay[3].label = this.getTradC('CreateEvent.weekBeforeAt');
            this.notificationAllDay[4].label = this.getTrad('CreateEvent.personalized');

            this.notificationAllDaySelected.push(this.notificationAllDay[0]);

            this.repetition[0].label = this.getTradC('CreateEvent.noRepeat');
            this.repetition[1].label = this.getTradC('CreateEvent.daily');
            this.repetition[2].label = this.getTradC('CreateEvent.weekly');
            this.repetition[3].label = this.getTradC('CreateEvent.monthly');
            this.repetition[4].label = this.getTradC('CreateEvent.yearly');
            this.repetition[5].label = this.getTradC('CreateEvent.everyWeekday');
            this.repetition[6].label = this.getTradC('CreateEvent.personalized');
        },

        beforeUpdate() {
            if (!this.updatedOnce) {
                this.updatedOnce = true;
                this.start = this.event.roundedHour;
            }
        },

        created() {
            this.createHoursArray(this.startHoursArray);
        },

        setup(props, { emit }) {
            // ————————————————————————————————————
            //* ——— Local Event
            // ————————————————————————————————————

            const eventLocal = ref(JSON.parse(JSON.stringify(props.event)));
            const resetEventLocal = () => {
                eventLocal.value = JSON.parse(JSON.stringify(props.event));

                // Only get date from event
                if (eventLocal.value.start) eventLocal.value.start = new Date(eventLocal.value.start).toISOString().substr(0, 10);
                if (eventLocal.value.end) eventLocal.value.end = new Date(eventLocal.value.end).toISOString().substr(0, 10);
            };
            watch(
                () => props.event,
                () => {
                    resetEventLocal();
                },
                { deep: true },
            );

            // ————————————————————————————————————
            //* ——— Form
            // ————————————————————————————————————

            const isStartDateMenuOpen = ref(false);
            const isEndDateMenuOpen = ref(false);

            // ————————————————————————————————————
            //* ——— Perfect Scrollbar
            // ————————————————————————————————————

            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false,
                wheelSpeed: 0.7,
            };

            return {
                // Local Event
                eventLocal,
                resetEventLocal,

                // Form
                isStartDateMenuOpen,
                isEndDateMenuOpen,

                // Perfect Scrollbar
                perfectScrollbarSettings,

                // Icons
                icons: {
                    mdiTrashCanOutline,
                    mdiClose,
                },
            };
        },
    };
</script>

<style lang="scss">
    .ps-calendar-event-handler {
        height: calc(100% - 44px - 24px - 2px);

        // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
        // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
        .select-guest {
            .v-select__selections {
                .v-chip--select {
                    margin-bottom: 6px;
                    margin-top: 6px;
                }
            }
        }
    }
</style>
