var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600"},on:{"click:outside":_vm.closeOnOverlay},model:{value:(_vm.isActiveDialog),callback:function ($$v) {_vm.isActiveDialog=$$v},expression:"isActiveDialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-1 text-sm-h5"},[_vm._v(" "+_vm._s(_vm.getTrad('DialogNotAllDayNotification.customNotification'))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"item-text":"label","item-value":"value","items":_vm.notificationMethodArray,"hide-details":"auto","menu-props":{ offsetY: true },"dense":"","outlined":""},model:{value:(_vm.notificationMethod),callback:function ($$v) {_vm.notificationMethod=$$v},expression:"notificationMethod"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.getTrad('DialogNotAllDayNotification.time'),"rules":_vm.notificationTime == 'minutes'
                                            ? 'min_value:1|max_value:40320|required'
                                            : _vm.notificationTime == 'hours'
                                            ? 'min_value:1|max_value:672|required'
                                            : _vm.notificationTime == 'days'
                                            ? 'min_value:1|max_value:28|required'
                                            : 'min_value:1|max_value:4|required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","type":"number","error-messages":errors},model:{value:(_vm.notificationTimeBefore),callback:function ($$v) {_vm.notificationTimeBefore=$$v},expression:"notificationTimeBefore"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"item-text":"label","item-value":"value","items":_vm.notificationTimeArray,"hide-details":"auto","menu-props":{ offsetY: true },"dense":"","outlined":""},model:{value:(_vm.notificationTime),callback:function ($$v) {_vm.notificationTime=$$v},expression:"notificationTime"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":_vm.closeOnOverlay}},[_vm._v(" "+_vm._s(_vm.getTrad('DialogNotAllDayNotification.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary darken-1","type":"submit","text":""}},[_vm._v(" "+_vm._s(_vm.getTrad('DialogNotAllDayNotification.save'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }