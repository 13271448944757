<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="600" @click:outside="closeOnOverlay">
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top rounded="pill" :centered="true" :timeout="5000"
                ><v-layout align-center pr-4>
                    <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                            <strong>{{ getTrad('RemoveCalendarDialog.error') }}</strong>
                        </div>
                        <div>{{ getTrad('RemoveCalendarDialog.errorText') }}</div>
                    </v-layout>
                </v-layout></v-snackbar
            >
            <v-card>
                <v-card-title class="text-subtitle-1 text-sm-h5"> {{ getTrad('RemoveCalendarDialog.title') }} </v-card-title>

                <v-card-text>{{ getTrad('RemoveCalendarDialog.text') }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error darken-1" :loading="loading" text @click="closeOnOverlay"> {{ getTrad('RemoveCalendarDialog.cancel') }} </v-btn>

                    <v-btn color="primary darken-1" :loading="loading" type="submit" @click="removeCalendar" text> {{ getTrad('RemoveCalendarDialog.remove') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import getTrad from '../i18n/getTrad';
    import { mdiAlertCircle } from '@mdi/js';
    export default {
        props: ['isActive', 'isLoading', 'showSnackbar'],
        mixins: [getTrad],

        data() {
            return {
                isActiveDialog: null,
                loading: null,

                snackbar: {
                    visible: false,
                    icon: mdiAlertCircle,
                    color: 'error',
                },
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            removeCalendar() {
                this.$emit('remove');
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                console.log(newVal);
                this.isActiveDialog = newVal;
            },
            isLoading: function (newVal, oldVal) {
                this.loading = newVal;
            },
            showSnackbar: function (newVal, oldVal) {
                this.snackbar.visible = newVal;
            },
        },
    };
</script>
